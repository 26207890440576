import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { IComponentConfig } from '../components/components.component';

interface ISuggestionsSettings {
  noResultsMessage?: string;
  moreBtn?: {
    label: string,
  } | false
}

@Component({
  selector: 'app-workflow-suggestions',
  templateUrl: './workflow-suggestions.component.html',
  styleUrls: ['./workflow-suggestions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowSuggestionsComponent implements OnInit {
  @Input() config: IComponentConfig<ISuggestionsSettings>;
  @Input() refreshSubject: Subject<any>;
  @ViewChild('swiperRef', { static: false }) swiperRef?: SwiperComponent;
  @Input() context;


  slidesOffsetAfter = 0;
  swiperOptions: SwiperOptions = {
    slidesPerView: 1.2,
    breakpoints: {
      0: {
        slidesPerView: 1.2,
      },
      520: {
        slidesPerView: 2.2,
      }
    },
    parallax: {
      enabled: true
    },
    freeMode: {
      enabled: true,
      sticky: true,
    },
    speed: 1000,
    autoplay: {
      delay: 2000,
    }
  };

  suggestions: any[] = [];

  activeStartIndex = 0;
  activeEndIndex = 0;

  constructor(
    private changeRef: ChangeDetectorRef,
    private elementRef: ElementRef
  ) { }


  get settings(): ISuggestionsSettings {
    return this.config.settings;
  }

  ngOnInit() {
    if (this.refreshSubject) {
      this.refreshSubject.subscribe(() => {
        this.refreshData();
      });
    }
  }


  async refreshData() {
    this.suggestions.push({
      workflowimage: 'https://images.pexels.com/photos/3984340/pexels-photo-3984340.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    })
    this.suggestions.push({
      workflowimage: 'https://images.pexels.com/photos/3984340/pexels-photo-3984340.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    })
    this.suggestions.push({
      workflowimage: 'https://images.pexels.com/photos/3984340/pexels-photo-3984340.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    })
    this.changeRef.detectChanges();
  }



  onSlideChange(when: 'START' | 'END', swiper: any) {
    const elm: any = this.elementRef.nativeElement;
    this.slidesOffsetAfter = (elm.offsetWidth * 0.2) - 20;

    switch (when) {
      case 'START':
        this.activeStartIndex = swiper[0].realIndex;
        this.activeEndIndex = undefined;
        break;
      case 'END':
        this.activeStartIndex = swiper[0].realIndex;
        this.activeEndIndex = swiper[0].realIndex;
        break;
    }
    this.changeRef.detectChanges();
  }
}
