import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { SessionService } from './session.service';
import { SessionStoreService } from './session.store';

@Injectable({
  providedIn: 'root'
})
export class SessionGuardService implements CanActivate {

  constructor(
    private sessionStore: SessionStoreService,
    private sessionService: SessionService,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    await this.sessionService.refreshUserSettings();
    const hasAuth = this.sessionStore.isAuthenticated;
    return hasAuth
  }
}

@Injectable({
  providedIn: 'root'
})
export class NonSessionGuardService implements CanActivate {

  constructor(
    private sessionStore: SessionStoreService,
    private sessionService: SessionService
  ) { }
  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const hasAuth = this.sessionStore.isAuthenticated;
    if (hasAuth) {
      this.sessionService.goToLandingPage();
    }
    return !hasAuth
  }
}
