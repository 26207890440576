import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { IProduct } from 'src/app/api/product/product.resource';
import { ProductService } from 'src/app/api/product/product.service';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { MessageService } from '../../message.service';
import { UtilService } from '../../util.service';
import { IComponentConfig } from '../components/components.component';
import { IDelivery } from 'src/app/api/order/order.resource';
import moment from 'moment';

interface IProductCarouselSettings {
  noResultsMessage?: string;
  asSuggestion?: boolean;
  query?: any;
  header?: string;
  description?: string;
}

@Component({
  selector: 'app-products-carousel',
  templateUrl: './products-carousel.component.html',
  styleUrls: ['./products-carousel.component.scss'],
})
export class ProductsCarouselComponent implements OnInit {
  @Input() config: IComponentConfig<IProductCarouselSettings>;
  @Input() refreshSubject: Subject<any>;
  @Input() context;

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  activeStartIndex = 0;
  activeEndIndex = 0;
  slidesOffsetAfter = 0;

  products: IProduct[] = [];

  private boughtProducts: string[] = [];

  swiperOptions: SwiperOptions = {
    slidesPerView: 1,
    parallax: {
      enabled: true
    },
    freeMode: {
      enabled: true,
      sticky: true,
    },
    shortSwipes: false,

    speed: 500,
    pagination: {
      enabled: true,
      clickable: true,
      type: 'bullets'
    },
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
      pauseOnMouseEnter: false,
    }
  };

  constructor(
    private service: ProductService,
    private utilService: UtilService,
    private changeRef: ChangeDetectorRef,
    private elementRef: ElementRef,
    private activeRoute: ActivatedRoute,
    private messageService: MessageService,
  ) { }

  private getQueryParams(): any {
    let queryParams = {};
    let route = this.activeRoute.snapshot;
    while (route.parent) {
      queryParams = { ...queryParams, ...route.queryParams };
      route = route.parent;
    }
    return queryParams;
  }

  ngOnInit() {
    if (this.refreshSubject) {
      this.refreshSubject.subscribe(() => {
        this.refreshData();
      });
    }

    this.messageService.subscribe('personal-planning.refresh', (data: {
      suggestions: IProduct[],
      deliveries: IDelivery[]
    }) => {
      const { deliveries } = data;
      this.boughtProducts = deliveries.map(del => del.mainProduct.productkey);
    });

  }


  get settings(): IProductCarouselSettings {
    return this.config?.settings;
  }

  get query() {
    const query = JSON.parse(JSON.stringify(this.config?.settings?.query || {}));
    for (const key of Object.keys(query)) {
      if (typeof query[key] === 'string') {
        query[key] = this.utilService.interpolateStr(this.context || {}, query[key]);
        if (!query[key]) {
          delete query[key];
        }
      }
    }

    return query;
  }

  get suggestions() {
    return this.products.filter(p => this.boughtProducts.indexOf(p.productkey) === -1).splice(0, 4);
  }

  async refreshData() {
    const queryParams = this.getQueryParams();
    this.products = [];
    this.changeRef.detectChanges();
    let query = this.query;
    const date = this.context?.selectedDate || queryParams.selectedDate;
    if (date) {
      query.selecteddate = date;
    }
    if (moment(date).isSameOrAfter(Date.now(), 'date')) {
      this.products = (await this.service[this.context.selectedDate ? 'queryDate' : 'query']({ ...query, ...queryParams, skipLoader: true })).results;
    } else {
      this.products = [];
    }
    if (this.products) {
      this.messageService.broadcast('products-carousel.refresh', { products: this.products })
    }
    this.changeRef.detectChanges();
    if (this.swiper && this.swiper.swiperRef) {
      this.swiper.swiperRef.slideTo(0);
    }
  }

  onSlideChange(when: 'START' | 'END') {
    const elm: any = this.elementRef.nativeElement;
    this.slidesOffsetAfter = (elm.offsetWidth * 0.2) - 20;
    switch (when) {
      case 'START':
        this.activeStartIndex = this.swiper.swiperRef.activeIndex;
        this.activeEndIndex = undefined;
        break;
      case 'END':
        this.activeStartIndex = this.swiper.swiperRef.activeIndex;
        this.activeEndIndex = this.swiper.swiperRef.activeIndex;
        break;
    }
    this.changeRef.detectChanges();
  }

  trackProduct(index, pro) {
    return pro.productkey;
  }
}
