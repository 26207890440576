import { Pipe, PipeTransform } from '@angular/core';
import { AppTranslateService } from './translate.service';

@Pipe({
  name: 'appTranslate'
})
export class AppTranslatePipe implements PipeTransform {

  constructor(private service: AppTranslateService) { }

  transform(input: string): string {
    return this.service.instant(input);
  }
}