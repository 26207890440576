import { Component } from '@angular/core';
import { ActivationEnd, Event, Router } from '@angular/router';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { TinyColor, tinycolor } from '@thebespokepixel/es-tinycolor';
import OneSignal from 'onesignal-cordova-plugin';
import { SessionService } from './api/session/session.service';
import { SessionStoreService } from './api/session/session.store';
import { MessageService } from './core/message.service';
import { AppTranslateService } from './core/translate.service';
import ENV from './env.json';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  systemError: HTMLIonToastElement;
  systemInfo: HTMLIonToastElement;

  themeColor;

  constructor(
    private messageService: MessageService,
    private alertCtrl: AlertController,
    private translateService: AppTranslateService,
    private toastCtrl: ToastController,
    private sessionStore: SessionStoreService,
    private sessionService: SessionService,
    private platform: Platform,

    private router: Router
  ) {
    this.setTheme();
    this.messageService.subscribe('theme.update', (primary) => {
      this.setCustomColor(primary);
    });

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      this.setCustomColor(this.themeColor);
    });
    this.messageService.subscribe('system.error', async (message) => {
      if (this.systemError) {
        this.systemError.dismiss();
      }
      this.systemError = await this.toastCtrl.create({
        header: this.translateService.instant('error_message_header'),
        duration: 6000,
        position: 'bottom',
        icon: ENV.appBackend + '/assets/fa/regular/circle-xmark.svg',
        message: this.translateService.instant(message),
        buttons: [this.translateService.instant('Dismiss')],
        color: 'dark'
      });
      this.systemError.present();
    });

    this.messageService.subscribe('system.info', async (message) => {
      if (this.systemInfo) {
        this.systemInfo.dismiss();
      }
      this.systemInfo = await this.toastCtrl.create({
        header: this.translateService.instant('info_message_header'),
        duration: 3000,
        position: 'bottom',
        icon: ENV.appBackend + '/assets/fa/regular/circle-info.svg',
        message: this.translateService.instant(message),
        buttons: [this.translateService.instant('Dismiss')],
        color: 'light'
      });
      this.systemInfo.present();
    });

    if ((window as any).cordova) {
      CapacitorUpdater.addListener('updateAvailable', async (res) => {
        const alert = await this.alertCtrl.create({
          header: 'Update ' + res.bundle.version + ' Available',
          buttons: ['Install']
        });
        await alert.present();
        await alert.onDidDismiss()
        CapacitorUpdater.set(res.bundle);
      });
    }
    this.router.events.subscribe(async (event: Event) => {
      switch (true) {
        case event instanceof ActivationEnd:
          this.setTheme();
          this.setCustomColor(null);
          break;
      }
    });
    this.platform.ready().then(() => {
      try {
        OneSignal.initialize('0e276e87-f8c7-425c-a8a1-a37bc9b08d21');
        OneSignal.Notifications.addEventListener("click", (data) => {
          try {
            this.handlePushdata(data.notification.additionalData)
          } catch (error) { }
        });

        OneSignal.Notifications.requestPermission();

      } catch (error) { }
    });
  }

  handlePushdata(pushData: any) {
    this.router.navigate(['/base']).then(() => {
      switch (pushData.obj.toUpperCase()) {
        case 'CHATS':
          this.router.navigate(['/base/chats/detail/' + pushData.key]);
          break;
        case 'ORDERS':
          this.router.navigate(['/base/orders/detail/' + pushData.key]);
          break;
        case 'PRODUCTS':
          this.router.navigate(['/base/products/detail/' + pushData.key]);
          break;
      }
    });

  }

  async setTheme() {
    const elm = document.getElementById('THEME-STYLE');
    if (!elm) {
      await this.sessionService.refreshUserSettings();
      const theme: {
        colors: {
          [x: string]: string
        }
      } = this.sessionStore.settings.theme;
      const colors: {
        [x: string]: TinyColor
      } = {}

      if (theme) {
        try {
          const lightArr: string[] = [];
          const darkArr: string[] = [];
          // theme.colors['primary'] = '#FC594F';
          for (const color of Object.keys(theme.colors)) {
            let hex, rgb, contrastHex, contrastRGB, shadeHex, tintHex;
            if (theme.colors[color].startsWith('#')) {
              const t = tinycolor(theme.colors[color], {});
              hex = '#' + t.toHex(false);
              rgb = t.toRgbArray().join(', ');
              const shade = tinycolor(t, {}).darken(10);
              const tint = tinycolor(t, {}).lighten(10);

              const contrast = tinycolor(t.darken(10).isDark() ? theme.colors['light'] || '#f4f4f4' : theme.colors['dark'] || '#222222', {});
              contrastHex = '#' + contrast.toHex(false);
              contrastRGB = contrast.toRgbArray().join(', ');

              shadeHex = '#' + shade.toHex(false);
              // const shadeRGB = shade.toRgbArray().join(', ');

              tintHex = '#' + tint.toHex(false);
              // const tintRGB = tint.toRgbArray().join(', ');
            } else {
              hex = `var(--ion-color-${theme.colors[color]})`;
              rgb = `var(--ion-color-${theme.colors[color]}-rgb)`;
              contrastHex = `var(--ion-color-${theme.colors[color]}-contrast)`;
              contrastRGB = `var(--ion-color-${theme.colors[color]}-rgb)`;
              shadeHex = `var(--ion-color-${theme.colors[color]}-shade)`;
              tintHex = `var(--ion-color-${theme.colors[color]}-tint)`;
            }

            let css;
            switch (color) {
              case 'background':
                css = `
              --ion-background-color    : ${hex};
              --ion-background-color-rgb: ${rgb};
    
            `;
                break;
              case 'header':
                css = `
                --ion-color-header         : ${hex};
                --ion-color-header-rgb     : ${rgb};
                --ion-color-header-contrast: var(--ion-text-color);

            `
                break;
              case 'light':
                css = `
              --ion-color-${color}             : ${hex};
              --ion-color-${color}-rgb         : ${rgb};
              --ion-color-${color}-contrast    : var(--ion-color-primary);
              --ion-color-${color}-contrast-rgb: var(--ion-color-primary-rgb);
              --ion-color-${color}-shade       : ${shadeHex};
              --ion-color-${color}-tint        : ${tintHex};

            `
                break;
              case 'dark':
                css = `
              --ion-color-${color}             : ${hex};
              --ion-color-${color}-rgb         : ${rgb};
              --ion-color-${color}-contrast    : var(--ion-color-primary);
              --ion-color-${color}-contrast-rgb: var(--ion-color-primary-rgb);
              --ion-color-${color}-shade       : ${shadeHex};
              --ion-color-${color}-tint        : ${tintHex};

            `
                break;
              default:
                css = `
              --ion-color-${color}             : ${hex};
              --ion-color-${color}-rgb         : ${rgb};
              --ion-color-${color}-contrast    : ${contrastHex};
              --ion-color-${color}-contrast-rgb: ${contrastRGB};
              --ion-color-${color}-shade       : ${shadeHex};
              --ion-color-${color}-tint        : ${tintHex};

            `
                break;
            }
            if (css) {
              lightArr.push(css);
              if (color === 'light' || color === 'dark') {
                darkArr.push(css
                  .replace(/ --ion-color-light/gmi, ' --ion-color-#light')
                  .replace(/ --ion-color-dark/gmi, ' --ion-color-#dark')
                  .replace(/ --ion-color-#light/gmi, ' --ion-color-dark')
                  .replace(/ --ion-color-#dark/gmi, ' --ion-color-light')
                );
              }
            }

          }
          const lightStr = `
          :root {
            ${lightArr.join('')}
          }
          .ios body .md body, body {
            ${lightArr.join('')}
          }`;

          const newElm = document.createElement('style');
          newElm.innerText = `
          ${lightStr}

          @media (prefers-color-scheme: dark) {
            body {
            ${darkArr.join('')}
            }
          }
        `.replace(/\n/gmi, '').replace(/  /gmi, '');

          newElm.id = 'THEME-STYLE';
          document.body.appendChild(newElm);

        } catch (error) { }
      }
    }
  }

  async setCustomColor(color) {
    try {
      const elm = document.getElementById('CUSTOM-STYLE');
      if (elm) {
        const themeElm = document.querySelector('meta[name=theme-color]');
        if (themeElm) {
          themeElm.setAttribute('content', '#e9e9e9');
        }
        elm.remove();
      }
      this.themeColor = color;
      if (color) {
        const LIGHT_HEX = '#f4f4f4';
        const DARK_HEX = '#222222'
        const primary = tinycolor(color, {});
        if (!primary.isDark()) {
          while (!primary.isDark()) {
            primary.darken(10);
          }
        }

        // if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        //   base = tinycolor(primary, {});
        // } else {
        //   if (tinycolor(base, {}).lighten(20).isDark()) {
        //     while (base.isLight()) {
        //       base.darken(20);
        //     }
        //   }
        //   base.darken(10);
        // }

        const contrast = tinycolor(!primary.isDark() ? DARK_HEX : LIGHT_HEX, {});
        const baseContrast = tinycolor(!primary.isDark() ? DARK_HEX : LIGHT_HEX, {});

        const themeElm = document.querySelector('meta[name=theme-color]');
        if (themeElm) {
          themeElm.setAttribute('content', '#' + primary.toHex(false));
        }
        const baseHex = primary.toHex(false);
        const baseRgb = primary.toRgbArray().join(', ');

        const primaryHex = primary.toHex(false);
        const primaryRGB = primary.toRgb() as { r: number; g: number; b: number };
        const primaryShadehex = primary.darken(10).toHex(false);
        const primaryTinthex = primary.lighten(10).toHex(false);

        const contrastRGB = contrast.toRgb() as { r: number; g: number; b: number };;
        const contrastHex = contrast.toHex(false);

        const colorButtons = this.sessionStore.settings.themeButtons;
        const primaryStyle = `
        --ion-color-primary: #${primaryHex};
        --ion-color-primary-rgb: ${primaryRGB.r}, ${primaryRGB.g}, ${primaryRGB.b};
        --ion-color-primary-contrast    : #${contrastHex};
        --ion-color-primary-contrast-rgb: ${contrastRGB.r}, ${contrastRGB.g}, ${contrastRGB.b};
        --ion-color-primary-shade       : #${primaryShadehex};
        --ion-color-primary-tint        : #${primaryTinthex};
`
        let style = `
        ${colorButtons ? primaryStyle : ''}

        --ion-color-header-contrast: var(--ion-text-color);
        --ion-color-header-rgb: ${baseRgb};
        --ion-color-header: #${baseHex};
        `;

        const styles = '.ios body .md body, body, .ios body.dark .md body.dark, body.dark {' + style + '}';
        const cssData = ':root {' + style + '} ' + styles + '  @media (prefers-color-scheme: dark) { ' + styles + ' }';
        const newElm = document.createElement('style');
        newElm.innerText = cssData;
        newElm.id = 'CUSTOM-STYLE';
        document.body.appendChild(newElm);
      }
    } catch (error) { }
  }


}
