@if (items && items.length > 0) {
<ion-card>
  @for (item of items; track $index; let last = $last) {
  <app-announcement-item [lines]="last ? 'none' : ''" [item]="item"></app-announcement-item>
  }
</ion-card>
} @else {
<div class="no-results-message">
  {{(settings.noResultsMessage || 'No items')| appTranslate}}
</div>
}
<div *ngIf="items?.length > 0 && total !== visible" class="ion-text-center" style="margin:14px 7px;">
  <ion-button *ngIf="settings.moreBtn" fill="clear" color="primary">
    {{settings.moreBtn.label || 'view activities'}}
    <ion-icon slot="end" [faIcon]="(settings.moreBtn.icon || 'chevron-right') "></ion-icon>
  </ion-button>
</div>