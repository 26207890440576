import { Injectable } from '@angular/core';
import { ServiceBase } from 'src/app/core/service.base';
import { UtilService } from 'src/app/core/util.service';
import { IQueryParams } from '../api.module';
import { ProductResourceService } from './product.resource';
import { WebshopcategoryResourceService } from './webshopcategory.resource';

export interface IProductCategory {
  nmbr: number;
  companynmbr: number;
  language1: string;
  name?: string;
  webshopcategory1: string;
  webshopcategory2: string;
  webshopcategory3: string;
  webshopcategory4: string;
  webshopcategory5: string;
  webshopcategory6: string;
  sequence: number;
  webshopcategory1name: string;
  webshopcategory2name: string;
  webshopcategory3name: string;
  webshopcategory4name: string;
  webshopcategory5name: string;
  webshopcategory6name: string;
  description: string;
  shortdescription: string;
  title: string;
  metatitle: string;
  categoryimage: string;
  displaysequence1: number;
  displaysequence2: number;
  displaysequence3: number;
  displaysequence4: number;
  displaysequence5: number;
  displaysequence6: number;
  products: number;
  categoryname: string;
  childeren: IProductCategory[];
}


@Injectable({
  providedIn: 'root'
})
export class ProductService extends ServiceBase {

  constructor(
    private resource: ProductResourceService,
    private categoryResource: WebshopcategoryResourceService,
    private utilService: UtilService,
  ) {
    super('ProductService');
  }


  getOne(productkey: string, query?: any) {
    if (!productkey) {
      this.utilService.broadcastError(this.getCtx('getOne'), 'error_missing_productkey', true);
    }
    return this.utilService.handleServerCall(this.getCtx('getOne'), async () => await this.resource.getOne({ productkey, ...query }));
  }

  query(q: IQueryParams) {
    return this.utilService.handleServerCall(this.getCtx('query'), async () => await this.resource.query(q), q?.skipLoader);
  }

  queryDate(q: IQueryParams) {
    return this.utilService.handleServerCall(this.getCtx('queryDate'), async () => await this.resource.queryDate(q), q?.skipLoader);
  }

  queryCategories(query: IQueryParams) {
    return this.utilService.handleServerCall(this.getCtx('queryCategories'), async () => await this.categoryResource.query(query), query?.skipLoader);
  }

  getCategory(nmbr: number) {
    if (!nmbr) {
      this.utilService.broadcastError(this.getCtx('getCategory'), 'error_missing_category', true);
    }
    return this.utilService.handleServerCall(this.getCtx('getCategory'), async () => await this.categoryResource.getOne({ nmbr }));
  }

  getProducts(nmbr: number) {
    if (!nmbr) {
      this.utilService.broadcastError(this.getCtx('getProducts'), 'error_missing_category', true);
    }
    return this.utilService.handleServerCall(this.getCtx('getProducts'), async () => await this.categoryResource.getProducts({ nmbr }));
  }
}
