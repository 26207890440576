<ion-card [routerLink]="['/base/chats/detail', mainChat.nmbr]" [button]="true" style="padding:0px;" *ngIf="mainChat">
  <div style="position: relative;">
    <div style="z-index:1; width: 50%; position: absolute; bottom:0; right: 0; padding:var(--ion-padding); text-align: left; width: 100%; height:auto; justify-content: flex-start;" class="myb-flex">
      <div class="wrapper horizontal">
        <div class="content">
          <div class="header" style="max-width: 60%;" [innerHTML]="chatName"></div>
        </div>
        @for (img of avatars; track $index; let last = $last;let first = $first) {
        <ion-avatar style="width:25px; height:25px; margin-left: -9px !important; float:left; align-self: flex-start;" [ngStyle]="{'margin-left': !first ? '0px !important' : ''}">
          <ion-img [src]="img"></ion-img>
        </ion-avatar>
        }
        <ion-badge *ngIf="mainChat.unread" slot="end" color="danger">{{mainChat.unread}}</ion-badge>
      </div>
    </div>
    <div class="background" style="position: relative;">
    </div>
  </div>
  <ion-item color="primary">
    <div class="myb-flex">
      <div class="wrapper horizontal">
        <div class="content">
          <div class="description ion-clamp-3" [innerHTML]="paragraph"></div>
        </div>
      </div>
    </div>
    <ion-icon slot="end" faIcon="chevron-right"></ion-icon>
  </ion-item>
</ion-card>