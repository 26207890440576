import { Injectable } from '@angular/core';
import { IResourceMethodPromise, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod } from '@ngx-resource/core';
import { IMongooseResult, IQueryParams } from '../api.module';
import { SafeResourceUrl } from '@angular/platform-browser';


export interface IWorkflow {
  nmbr?: number;
  employee?: string;
  category?: string;
  workflowname?: string;
  creationdate?: number;
  changedate?: number;
  workflowsteps?: number;
  subcategory?: string;
  workflowtodo?: number;
  workflowrelation?: string;
  ispublic?: string;
  menulevel?: number;
  stepdescription?: string;
}

export interface IWorkflowStep {
  workflownmbr?: number;
  sequence?: number;
  employee?: string;
  description?: string;
  workflowshortkey?: string;
  workflowpage?: string;
  workflowstepgroup?: string;
  embededVideos: (string | SafeResourceUrl)[];
}


@ResourceParams({
  pathPrefix: '/api/guest/workflow'
})
@Injectable({
  providedIn: 'root'
})
export class WorkflowResourceService extends Resource {

  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: ''
  })
  query: IResourceMethodPromise<IQueryParams, IMongooseResult<IWorkflow>>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/{!nmbr}'
  })
  getOne: IResourceMethodPromise<IQueryParams, IWorkflow>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/{!nmbr}/step'
  })
  queryStep: IResourceMethodPromise<IQueryParams, IWorkflowStep>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/category'
  })
  queryCategory: IResourceMethodPromise<IQueryParams, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/category/{!category}'
  })
  querySubCategory: IResourceMethodPromise<IQueryParams, any>;
}
