<ion-card *ngIf="otherChats.length > 0">
  <!-- <app-chat-item *ngIf="mainChat" [item]="mainChat" [isMain]="true" [lines]="otherChats.length > 0 ? '' : 'none'"></app-chat-item> -->
  @for (chat of otherChats; track chat.nmbr; let last = $last;) {
  <app-chat-item [item]="chat" [lines]="last ? 'none' : ''"></app-chat-item>
  }
</ion-card>
<div *ngIf="otherChats.length > 0 && visible !== total" class="ion-text-center" style="margin:14px 7px;">
  <ion-button *ngIf="settings.moreBtn" [routerLink]="['/base/chats/explore']" fill="clear" color="primary">
    {{settings.moreBtn.label || 'All chats'}}
    <ion-icon slot="end" [faIcon]="settings.moreBtn.icon || 'chevron-right'"></ion-icon>
  </ion-button>
</div>