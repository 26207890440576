<ion-item lines="none" [routerLink]="(link || ['/base/orders/detail', item.ordernmbr, item.delivery])" *ngIf="item && item.mainProduct; else noItem" [ngClass]="{'is-past': isPast, 'is-active': isActive, 'is-pending': isPending}">
  <ion-img slot="start" *ngIf="product.productimage" [src]="product.productimage"></ion-img>
  <div class="myb-flex">

    <div *ngIf="cource" class="wrapper horizontal">
      <div class="content" style="justify-content: center;">
        <div id="metadescription" class="featured description text-small">
          <ion-text color="primary">
            <span [innerHTML]="courceTimeFormatted"></span>
            <span *ngIf="cource.location">&nbsp;•&nbsp;{{cource.location}}</span>
          </ion-text>
        </div>
      </div>
      <div slot="end" [ngSwitch]="detailBtnType">
        <ion-badge color="light" *ngSwitchCase="'EXT_LINK'">
          <ion-icon faMode="solid" faIcon="arrow-up-right-from-square"></ion-icon>
          {{'has_link_label'| appTranslate}}
        </ion-badge>
        <ion-badge color="light" *ngSwitchCase="'ACCESSKEY'">
          <ion-icon faMode="solid" faIcon="door-closed"></ion-icon>
          {{'has_door_label'| appTranslate}}
        </ion-badge>
        <ion-badge color="light" *ngSwitchCase="'EBIKES'">
          <ion-icon faMode="solid" faIcon="person-biking"></ion-icon>
          {{'has_bike_label'| appTranslate}}
        </ion-badge>
      </div>
    </div>

    <div *ngIf="appointment" class="wrapper horizontal">
      <div class="content" style="justify-content: center;">
        <div id="metadescription" class="featured description text-small">
          <ion-text color="primary">
            <span [innerHTML]="appointmentTimeFormatted"></span>
          </ion-text>
        </div>
      </div>
      <div slot="end" [ngSwitch]="detailBtnType">
        <ion-badge color="light" *ngSwitchCase="'EXT_LINK'">
          <ion-icon faMode="solid" faIcon="arrow-up-right-from-square"></ion-icon>
          {{'has_link_label'| appTranslate}}
        </ion-badge>
        <ion-badge color="light" *ngSwitchCase="'ACCESSKEY'">
          <ion-icon faMode="solid" faIcon="door-closed"></ion-icon>
          {{'has_door_label'| appTranslate}}
        </ion-badge>
        <ion-badge color="light" *ngSwitchCase="'EBIKES'">
          <ion-icon faMode="solid" faIcon="person-biking"></ion-icon>
          {{'has_bike_label'| appTranslate}}
        </ion-badge>
      </div>
    </div>


    <div *ngIf="!courceTimeFormatted" class="wrapper horizontal">
      <div class="content">
        <div id="metadescription" class="description ion-clamp-2 featured" [innerHTML]="product?.metadescription"></div>
      </div>
    </div>
    <div class="wrapper horizontal">
      <div class="content">
        <div id="productname" class="header ion-clamp-3">
          <span *ngIf="product.quantity !== 0 && product.quantity !== 1 && product.quantity !== -1">{{product.quantity < 0 ? product.quantity*-1 : product.quantity}}x&nbsp;</span><span [innerHTML]="product.productname"></span>
        </div>
        @if(isPending) {
        <div style="display: flex; padding-top:7px;">
          <ion-badge color="primary">
            <ion-icon faMode="solid" faIcon="clock-rotate-left"></ion-icon>
            {{'pending_delivery_label'| appTranslate}}
          </ion-badge>
        </div>
        }
      </div>
      <ion-button style="align-self: flex-start; cursor: pointer; --padding-start:.4em; --padding-end:.4em; --padding-top:.4em; --padding-bottom:.4em;" *ngIf="item?.options?.length > 0 && !isPlanning && !previewMode" (click)="$event.preventDefault(); $event.stopPropagation(); showOptions = true;" color="primary" fill="clear" slot="end">
        <ion-icon [faIcon]="'circle-info'"></ion-icon>
      </ion-button>
    </div>
    <div *ngIf="courceTimeFormatted" class="wrapper horizontal">
      <div class="content">
        <div id="metadescription" class="description" [innerHTML]="product?.metadescription"></div>
      </div>
    </div>




    <div *ngIf="!isPlanning && !previewMode" class="wrapper horizontal">
      <div class="content">
        <div class="header" *ngIf="item.deliveryprice !== 0 && !previewMode" style="color:var(--ion-color-primary); margin:0px !important;" id="deliveryprice" [innerHTML]="item.deliveryprice | currency:'EUR' | rmspace"></div>
      </div>
      <ion-button style="align-self: flex-start; cursor: pointer; --padding-start:.4em; --padding-end:.4em; --padding-top:.4em; --padding-bottom:.4em;" (click)="$event.preventDefault(); $event.stopPropagation();removeItem.emit(item);" *ngIf="canEdit && product.quantity >= 0 && !previewMode" fill="clear" slot="end" size="small" color="danger">
        <ion-icon [faIcon]="'trash'"></ion-icon>
      </ion-button>
      <ion-chip style="align-self: flex-end;" fill="outline" slot="end" color="warning" *ngIf="product.quantity < 0 && product.rentalType">
        {{'product_return_label'}}
      </ion-chip>
    </div>
  </div>
  <!-- <ion-icon slot="end" [faIcon]="'chevron-right'"></ion-icon> -->
</ion-item>
<ion-item [lines]="product?.rentalType && !isPlanning && canEdit ? '' : 'none'" style="--min-height:32px; font-weight: 600;" *ngIf="item.altDeliveryDate && !isPlanning" color="light">
  <div class="description" style="margin:0px !important;">
    {{'delayed_delivery_label'| appTranslate}} {{item.altDeliveryDate | momentFormat:'dddd D MMMM'}}
  </div>
</ion-item>

<ion-item lines="none" style="--min-height:42px; font-weight: 600;" *ngIf="product?.rentalType && !isPlanning && canEdit" color="light">
  <div class="description" style="margin:0px !important;">
    <ng-container *ngIf="!product.rentalWindowExpired; else expiredMessage">
      <span>{{'rental_will_expire_countdown_label'| appTranslate}}</span>
      <span style="padding-left:5px; font-weight: 700;">{{rentalExpireSeconds | secondsCountdown}}</span>
    </ng-container>
    <ng-template #expiredMessage>
      {{'rental_has_expired_label'| appTranslate}}
    </ng-template>
  </div>
  <ion-button (click)="refreshRentalWindow()" *ngIf="!!product.rentalWindowExpired" color="light" size="small" slot="end">
    <ion-icon slot="start" [faIcon]="'rotate'"></ion-icon> {{'refresh_rental_window_btn'| appTranslate}}
  </ion-button>
</ion-item>
<ng-template #noItem>
  <ion-card style="position: relative; width: 100%;">
    <ion-card-content>
      <div *ngIf="notFound" class="frosted-overlay" style="display: flex; position: absolute; top:0px; left:0px; align-items: center; justify-content: center;">
        <div class="header" style="background: var(--ion-card-background); padding:.6em 1em; border-radius: 20px; white-space:nowrap">
          <ion-icon faIcon="trash"></ion-icon>
          {{'Removed'| appTranslate}}
        </div>
      </div>
      <ion-label style="padding: 0px 7px;">
        <h4 style=" font-weight:600!important;">
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
        </h4>
        <div>
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
        </div>
      </ion-label>
    </ion-card-content>
  </ion-card>
</ng-template>

<ion-modal #modal [breakpoints]="[1]" [handle]="false" initialBreakpoint="1" class="variable-height" (didDismiss)="showOptions = false;" [isOpen]="showOptions">
  <ng-template>


    <div style="max-height: calc(100vh - 120px); overflow-y: auto;">
      <ion-toolbar>
        <ion-title>
          {{'product_options_header'| appTranslate}}
        </ion-title>
        <ion-buttons slot="end">
          <ion-button fill="clear" size="medium" color="dark" (click)="modal.dismiss()">
            <ion-icon [faIcon]="'chevron-down'"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-card>
        @for (item of item.options; track $index; let last = $last) {
        @if (item.productkey) {
        <ion-item [lines]="last ? 'none' :''">
          <ion-img slot="start" *ngIf="item.productimage" [src]="item.productimage"></ion-img>
          <div class="myb-flex">
            <div class="wrapper vertical">
              <div class="header sub ion-clamp-3" *ngIf="item.productname" [innerHTML]="item.quantity+ 'x ' + item.productname"></div>
              <div *ngIf="item.metadescription" class="description featured text-muted" [innerHTML]="item.metadescription"></div>
            </div>
          </div>
        </ion-item>
        }@else {
        <ion-item [lines]="last ? 'none' :''">
          <div>
            <div class="header" [innerHTML]="item.productnameLabel| appTranslate"></div>
            <div class="description" [innerHTML]="item.productnameValue"></div>
          </div>
        </ion-item>
        }
        }
      </ion-card>
    </div>
  </ng-template>
</ion-modal>