@if (item) {
<ion-item [button]="true" (click)="open()" [lines]="lines">
  <div class="myb-flex">
    <div class="horizontal wrapper">
      <div class="content">
        <div class="header ion-clamp-2" [innerHTML]="item.lettername | sentenceCase"></div>
        <div class="description ion-clamp-2" [innerHTML]="item.description | sentenceCase"></div>
      </div>
    </div>
  </div>
  <ion-icon style="height:15px; width:15px;" color="medium" slot="end" [faIcon]="'chevron-right'"></ion-icon>
</ion-item>
} @else {
<ion-item style="position: relative; width: 100%;">
  <div *ngIf="notFound" class="frosted-overlay" style="display: flex; position: absolute; top:0px; left:0px; align-items: center; justify-content: center;">
    <div class="header" style="background: var(--ion-card-background); padding:.6em 1em; border-radius: 20px; white-space:nowrap">
      <ion-icon [faIcon]="'trash'"></ion-icon>
      {{'Removed'| appTranslate}}
    </div>
  </div>
  <ion-label style="padding: 0px 7px;">
    <h4 style=" font-weight:600!important;">
      <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
    </h4>
    <div>
      <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
    </div>
  </ion-label>
</ion-item>
}