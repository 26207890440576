import { Injectable } from '@angular/core';
import { IResourceMethodPromise, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod } from '@ngx-resource/core';
import { IMongooseResult, IQueryParams } from '../api.module';

@ResourceParams({
  pathPrefix: '/api/guest/webshopcategory'
})
@Injectable({
  providedIn: 'root'
})
export class WebshopcategoryResourceService extends Resource {

  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: ''
  })
  query: IResourceMethodPromise<IQueryParams, IMongooseResult<any>>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/{!nmbr}'
  })
  getOne: IResourceMethodPromise<{ nmbr: number }, IMongooseResult<any>>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/{!nmbr}/products'
  })
  getProducts: IResourceMethodPromise<{ nmbr: number }, IMongooseResult<any>>;

}
