<!--   -->
<ion-item [lines]="lines" button="true" [routerLink]="['/base/chats/detail', item.nmbr]">
  <div class="myb-flex">
    <div class="wrapper horizontal">
      <div class="content">
        <div class="header ion-clamp-1">
          <div [innerHTML]="chatName"></div>
        </div>
      </div>
      <div slot="end">
        @for (img of avatars; track $index; let first = $first; let last = $last) {
        <ion-avatar style="width:25px; height:25px; margin-left: -9px !important; float:left; align-self: flex-start;" [ngStyle]="{'margin-left': !first ? '0px !important' : ''}">
          <ion-img [src]="img"></ion-img>
        </ion-avatar>
        }
        <ion-chip color="danger" style="border-radius: 14px;" *ngIf="item?.unread">{{item.unread}}</ion-chip>
      </div>
    </div>
    <div class="wrapper horizontal">
      <div class="content">
        <div class="description ion-clamp-2" [innerHTML]="paragraph"></div>
      </div>
    </div>
  </div>
</ion-item>