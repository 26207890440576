import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IComponentConfig } from '../components/components.component';
import { ProductService } from 'src/app/api/product/product.service';
import { Subject } from 'rxjs';

interface IProductCategoriesSettings {
  query?: any;
}


@Component({
  selector: 'app-product-categories',
  templateUrl: './product-categories.component.html',
  styleUrls: ['./product-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCategoriesComponent implements OnInit {
  @Input() config: IComponentConfig<IProductCategoriesSettings>;
  @Input() refreshSubject: Subject<any>;
  @Input() context;

  categories: any[] = [];

  constructor(
    private productService: ProductService,
    private changeRef: ChangeDetectorRef,
  ) { }

  get settings() {
    return this.config.settings;
  }

  ngOnInit() {
    if (this.refreshSubject) {
      this.refreshSubject.subscribe(() => {
        this.refreshData();
      });
    }
  }

  async refreshData() {
    this.categories = (await this.productService.queryCategories(this.settings?.query)).results
    this.changeRef.detectChanges();
  }

  trackCat(index, item) {
    return item.nmbr;
  }


}
