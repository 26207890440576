@if (items && items.length > 0) {
<ion-card>
  <div class="myb-flex">
    <div class="wrapper horizontal">
      <div class="content">
        <ion-searchbar #searchbar (keydown.enter)="doSearch(searchbar.value)"></ion-searchbar>
      </div>
      <div slot="end" class="search-btn-container" *ngIf="searchbar.value">
        <ion-button slot="end" fill="clear" (click)="doSearch(searchbar.value)">
          {{'Search'| appTranslate}}
        </ion-button>
      </div>
    </div>
  </div>
  @for (item of items; track $index; let last = $last) {
  <ion-item [routerLink]="['/base/workflows/category', item.value]" button="true" [lines]="last ? 'none' : ''">
    <div class="myb-flex">
      <div class="wrapper vertical">
        <div class="wrapper horizontal">
          <div class="content">
            <div class="header no-margin">
              {{item.value | titlecase}}
            </div>
          </div>
          <ion-icon style="height:15px; width:15px;" color="medium" slot="end" [faIcon]="'chevron-right'"></ion-icon>
        </div>
      </div>
    </div>
  </ion-item>
  }
</ion-card>
} @else {
<div class="no-results-message">
  {{(settings.noResultsMessage || 'No items')| appTranslate}}
</div>
}

<div *ngIf="items?.length > 0 && this.visible !== this.total" class="ion-text-center" style="margin:14px 7px;">
  <ion-button *ngIf="settings.moreBtn" [routerLink]="['/base/workflows/explore']" size="small" fill="clear" color="primary">
    {{settings.moreBtn.label || 'Explore FAQ'}}
    <ion-icon slot="end" [faIcon]="settings.moreBtn.icon || 'chevron-right'"></ion-icon>
  </ion-button>
</div>