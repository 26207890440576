import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { SessionStoreService } from '../session/session.store';
import { ReservationService } from './reservation.service';
import { ReservationStoreService } from './reservation.store';

@Injectable({
  providedIn: 'root'
})
export class ReservationGuardService implements CanActivate {

  constructor(
    private service: ReservationService,
    private store: ReservationStoreService,
    private sessionStore: SessionStoreService,
    private navCtrl: NavController
  ) { }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    switch (this.sessionStore.userRole) {
      case 'LOCAL':
        return true;
      default:
        await this.service.refreshCurrentStored();
        switch (true) {
          case !this.store.hasReservation:
            if (state.url.toString().endsWith('/activate')) {
              return true;
            }
            this.navCtrl.navigateRoot(['/activate']);
            return false;
          case this.store.isProvisional:
            if (state.url.toString().endsWith('/wait')) {
              return true;
            }
            this.navCtrl.navigateRoot(['/wait']);
            return false;
          case this.store.reservationHasEnded:
            if (state.url.toString().endsWith('/wait')) {
              return true;
            }
            if (state.url.toString().indexOf('chats') !== -1) {
              return true;
            } else {
              this.navCtrl.navigateRoot(['/wait']);
              return false;
            }
          default:
            return true;
        }

    }

  }
}
