import { Component, Input, OnInit } from '@angular/core';
import { IChat } from 'src/app/api/chat/chat.resource';
import { IPageConfig } from '../../page.base';
import { SessionStoreService } from 'src/app/api/session/session.store';
import { UtilService } from '../../util.service';

@Component({
  selector: 'app-chat-item',
  templateUrl: './chat-item.component.html',
  styleUrls: ['./chat-item.component.scss'],
})
export class ChatItemComponent implements OnInit {
  @Input() item: IChat;
  @Input() link: any;
  @Input() lines: any;
  @Input() button: any;
  @Input() isMain: boolean;

  @Input() chatNameTemplate;

  constructor(
    private sessionStore: SessionStoreService,
    private utilService: UtilService,
  ) { }

  get avatars(): string[] {
    try {
      return this.item.memberImages
    } catch (error) { }
    return [];
  }

  get paragraph() {
    return `<strong>${this.lastCreator}:</strong>&nbsp;` + this.item?.lastparagraph.replace(/<[^>]*>/gmi, '');
  }

  get lastCreator() {
    return this.item?.lastmessage?.personalinfo?.firstname || this.item?.lastcreator;
  }

  get chatName() {
    if (this.chatNameTemplate) {
      return this.utilService.interpolateStr({ chat: this.item, isMainChat: this.isMain }, this.chatNameTemplate)
    }
    return this.item.chatname
  }

  get names() {
    try {
      const members = this.item.memberNames;
      return members;
    } catch (error) { }
    return [];
  }


  get namesStr() {
    const names: string[] = JSON.parse(JSON.stringify(this.names));
    const last = names.pop();
    const res = names.join(', ');
    return (res ? res + ' & ' : '') + last;
  }

  ngOnInit() {
    try {
      const config: IPageConfig<{}> = this.sessionStore?.settings?.staticPages['chat'];
      this.chatNameTemplate = config.settings.header.title;
    } catch (error) { }
  }

}
