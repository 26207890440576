<div *ngIf="suggestions.length > 0" class="ion-margin-top">
  <swiper class="autoplay" #swiper style="--autoplay-time: 6s;" [config]="swiperOptions" (slideChangeTransitionStart)="onSlideChange('START')" (slideChange)="onSlideChange('START')" (slideChangeTransitionEnd)="onSlideChange('END')">
    @for (p of suggestions; track p.productkey; let index = $index;) {
    <ng-template swiperSlide>
      <ion-card style="margin-top:calc(var(--ion-padding) * .5); margin-bottom:calc(var(--ion-padding) * 1.2);" [routerLink]="['/base/products/detail', p.productkey]" [queryParams]="{ selectedDate: context.selectedDate }" [ngClass]="{'active-start': index === activeStartIndex, 'active-end': index === activeEndIndex}">
        <img data-swiper-parallax="-20" class="bg-image" *ngIf="p.productimage" [src]="p.productimage" />
        <app-product-item [isSuggestion]="config?.settings?.asSuggestion" [hidePrice]="config?.settings?.asSuggestion" lines="none" image="none" [item]="p"></app-product-item>
      </ion-card>
    </ng-template>
    }
  </swiper>
</div>