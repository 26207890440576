import { Injectable } from '@angular/core';
import { ServiceBase } from './service.base';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class MyBusinessStoreService extends ServiceBase {

  constructor(
    private utilService: UtilService,
  ) {
    super('MyBusinessStoreService');
  }

  private get current() {
    return JSON.parse(localStorage.getItem('mybusiness') || '{}')
  }

  private set current(val) {
    localStorage.setItem('mybusiness', JSON.stringify(val));
  }

  set active(val: boolean) {
    if (this.active !== val) {
      if (val) {
        this.utilService.broadcastInfo(this.getCtx('active'), 'info_server_online');
      } else {
        this.utilService.broadcastError(this.getCtx('active'), 'error_server_offline');
      }
    }
    this.current = {
      ...this.current,
      active: val
    };
  }

  get active() {
    const val = this.current.active;
    return val !== undefined ? val : true;
  }
}