import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import ENV from '../env.json'

@Directive({
  selector: '[faIcon]',

})
export class FaIconDirective implements OnChanges {
  @Input() faMode: string = 'light';
  @Input() faIcon: string;

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
  ) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    const { faIcon, faMode } = changes;
    if (faMode?.firstChange || faMode?.currentValue !== faMode?.previousValue || faIcon?.firstChange || faIcon?.currentValue !== faIcon?.previousValue) {
      this.setSrc();
    }
  }


  setSrc(): void {
    if (this.faIcon) {
      let value;
      let [type, icon] = this.faIcon.split('/');
      if (!icon) {
        icon = '' + type;
        type = 'fa';
      }
      value = ENV.appBackend + '/assets/' + type + '/' + this.faMode + '/' + icon + '.svg';
      this.renderer.setAttribute(this.elRef.nativeElement, 'src', value)
    }
  }

}
