import { Injectable } from '@angular/core';
import { IResourceMethodPromise, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod } from '@ngx-resource/core';
import { IMongooseResult, IQueryParams } from '../../api/api.module';
import { Property } from '../product/product.resource';
import { IDomotica } from '../accommodation/accommodation.service';
import { IEBike } from 'src/app/core/components/controls/bike-control/bike-control.component';



export interface IAddDelivery {
  ordernmbr: number;
  productkey: string;
  rentalid?: number;
  rentalstartdatetime?: number;
  courceschedule?: { sequence: number, courcenmbr: number };
  quantity: number;
  deliverydate?: number;
  options?: {
    productkey: string;
    productname: string;
  }[];
}

export interface IOrderObject {
  order: IOrder;
  orderdeliveries: IDelivery[];
  firstDelivery: IDelivery
}

export interface IOrder {
  nmbr: number;
  ordertax?: number;
  ordername?: string;
  orderdiscount?: number;
  currencycode?: string;
  totalincl?: number;
  totalexcl?: number;
  yourorder?: string;
  orderprice?: number;
  paidtimestamp?: number;
  creationdate?: number;
  deliveries?: number;
  products?: number;
  timestamp?: number;
  paymentamount?: number;
  orderstate?: number;
  orderstatetext?: string;
}

export interface IDelivery {
  delivery?: number;
  ordernmbr: number;
  deliverynmbr: number;
  deliverydate?: any;
  appointment?: {
    employee: string;
    appointmentstart?: number;
    appointmentend?: number;
  }
  deliverydiscount?: number;
  deliveryquantity?: number;
  deliveryprice?: number;
  deliverytax?: number;
  products?: string;
  mainProduct?: IDeliveryProduct;
  options?: IDeliveryProduct[];
  altDeliveryDate: any;
  hideDeliveryDate?: boolean;
  orderstate?: number;
  orderstatetext?: string;
}

export interface ICourceSchedule {
  courcenmbr: number,
  companynmbr: number,
  sequence: number,
  scheduledate: number,
  starttime: number,
  endtime: number,
  employee: string,
  location: string,
  courcetxt1: string,
  courcetxt2: string,
  courcetxt3: string,
  courcetxt4: string,
  participants: number,
  maxparticipants: number,
}

export interface IDeliveryProduct {
  productkey?: string;
  sequence?: number;
  delivery?: number;
  productname?: string;
  courceschedule?: ICourceSchedule,
  productnameLabel?: string;
  productnameValue?: string;
  productimage?: null | string;
  productimages?: string[];
  quantity?: number;
  productsalesprice?: number;
  producttotalprice?: number;
  productdiscount?: number;
  producttax?: number;
  deliveryDays?: number | null;
  maxHourOfDay?: string;
  rentalWindowEndDateTime?: null;
  rentalWindowExpired?: string;
  accesskey?: IDomotica,
  ebikes: IEBike[];
  rentalType?: string;
  productprice?: number;
  listprice?: number | null;
  brand?: string;
  productspec?: string;
  producttxt1?: string;
  properties: Property[];
  metatitle?: string;
  metadescription?: string;
  unit?: null | string;
  altDeliverydate?: any;
  buttonLabel?: string,
  buttonLink?: string,
  buttonIcon?: string,
  domoticaType?: string,

}

@ResourceParams({
  pathPrefix: '/api/guest/order'
})
@Injectable({
  providedIn: 'root'
})
export class OrderResourceService extends Resource {

  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: ''
  })
  query: IResourceMethodPromise<IQueryParams, IMongooseResult<any>>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/delivery'
  })
  queryDelivery: IResourceMethodPromise<IQueryParams, IMongooseResult<any>>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/open'
  })
  getOpen: IResourceMethodPromise<IQueryParams, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/empty'
  })
  getEmpty: IResourceMethodPromise<IQueryParams, any>;


  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/{!nmbr}'
  })
  getOne: IResourceMethodPromise<IOrder, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/{!nmbr}/pay'
  })
  prepairPayment: IResourceMethodPromise<{ nmbr: number, method: any }, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/{!nmbr}/placeOrder'
  })
  placeOrder: IResourceMethodPromise<IOrder, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/{!nmbr}/parkOrder'
  })
  parkOrder: IResourceMethodPromise<IOrder, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/{!ordernmbr}/delivery'
  })
  addDelivery: IResourceMethodPromise<IAddDelivery, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '/{!ordernmbr}/delivery/{!deliverynmbr}'
  })
  removeDelivery: IResourceMethodPromise<IDelivery, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/{!ordernmbr}/delivery/{!deliverynmbr}'
  })
  updateDeliveryAmount: IResourceMethodPromise<IDelivery, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/{!ordernmbr}/delivery/{!deliverynmbr}/refreshRentalWindow'
  })
  refreshRentalWindow: IResourceMethodPromise<IQueryParams, any>;

}
