import { Injectable } from '@angular/core';
import { IResourceMethodPromise, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod } from '@ngx-resource/core';
import { IMongooseResult } from '../api.module';


export interface IAnnoucement {
  nmbr: number;
  letterkind?: string;
  lettername?: string;
  description?: string;
}



@ResourceParams({
  pathPrefix: '/api/guest/announcement'
})
@Injectable({
  providedIn: 'root'
})
export class AnnouncementResourceService extends Resource {

  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: ''
  })
  query: IResourceMethodPromise<any, IMongooseResult<IAnnoucement>>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/new'
  })
  getNew: IResourceMethodPromise<any, IAnnoucement[]>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/hook/{!hook}'
  })
  getHook: IResourceMethodPromise<any, IAnnoucement[]>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/{!nmbr}'
  })
  getOne: IResourceMethodPromise<{ nmbr: number }, IAnnoucement>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/{!nmbr}/paragraph'
  })
  getContents: IResourceMethodPromise<{ nmbr: number }, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/{!nmbr}/mark-as-read'
  })
  markAsRead: IResourceMethodPromise<{ nmbr: number }, IAnnoucement>;

}