@if (isLoading) {
<div class="no-results-message">
  <ion-spinner></ion-spinner>
</div>
} @else {
<div class="planning-wrapper" *ngIf="deliveries.length > 0">
  @for (del of deliveries; track del.deliverynmbr) {
  <ion-card button="true">
    <app-delivery-item [isPlanning]="true" [lines]="'none'" [item]="del"></app-delivery-item>
  </ion-card>
  }
</div>
<div class="planning-wrapper">
  <div *ngIf="suggestions.length > 0">
    @for (sug of suggestions; track sug.productkey) {
    <ion-card button="true">
      <app-product-item [linkQueryParams]="{ selectedDate: context.selectedDate, skipOrderProcess: true }" [isSuggestion]="true" [lines]="'none'" [item]="sug"></app-product-item>
    </ion-card>
    }
  </div>


  <ion-card button="true" *ngIf="settings.moreBtn && settings.moreBtn.link && !disableAdd" class="add-btn">
    <ion-item style="--background:transparent;" lines="none" [routerLink]="settings.moreBtn.link">
      <!-- <ion-img slot="start" [src]="'https://placehold.co/400x400'"></ion-img> -->
      <div class="myb-flex">
        <div class="wrapper horizontal">
          <div class="content">
            <div class="header ion-clamp-3">
              <ion-text color="primary">
                {{settings.moreBtn.label || 'view activities'}}
              </ion-text>
            </div>
          </div>
        </div>
      </div>
      <ion-icon slot="end" color="primary" [faIcon]="(settings.moreBtn.icon || 'chevron-right')"></ion-icon>
    </ion-item>
  </ion-card>
  <div class="no-results-message" *ngIf="suggestions.length === 0 && deliveries.length === 0 && disableAdd">
    {{(settings.noResultsMessage || 'No activities')| appTranslate}}
  </div>
  <div class="ion-padding-top">
    <!-- <ion-button *ngIf="settings.moreBtn && settings.moreBtn.link" [disabled]="disableAdd" [routerLink]="settings.moreBtn.link" [queryParams]="{ selectedDate: context.selectedDate }" size="large" fill="solid" expand="block" color="primary">
      {{settings.moreBtn.label || 'view activities'}}
      <ion-icon slot="end" [faIcon]="(settings.moreBtn.icon || 'chevron-right')"></ion-icon>
    </ion-button> -->
    <!-- <div class="ion-padding-top ion-text-center">
      <ion-button *ngIf="settings.moreBtn && settings.moreBtn.link" [routerLink]="settings.moreBtn.link" [queryParams]="{ selectedDate }" fill="clear" color="primary">
        {{'Personalize suggestions'}}
        <ion-icon slot="start" faMode="solid" [faIcon]="('cards-blank')"></ion-icon>
      </ion-button>
    </div> -->
  </div>
</div>
}