import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ResourceModule } from '@ngx-resource/core';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';
import { ButtonGroupComponent } from './button-group/button-group.component';
import { AnnouncementItemComponent } from './components/announcement-item/announcement-item.component';
import { AnnouncementsComponent } from './components/announcements/announcements.component';
import { ChatItemComponent } from './components/chat-item/chat-item.component';
import { ChatsComponent } from './components/chats/chats.component';
import { ComponentsComponent } from './components/components/components.component';
import { BikeControlComponent } from './components/controls/bike-control/bike-control.component';
import { BlindControlComponent } from './components/controls/blind-control/blind-control.component';
import { ClimateControlComponent } from './components/controls/climate-control/climate-control.component';
import { ControlCategoryComponent } from './components/controls/control-category/control-category.component';
import { ControlsComponent } from './components/controls/controls.component';
import { DoorControlComponent } from './components/controls/door-control/door-control.component';
import { LightControlComponent } from './components/controls/light-control/light-control.component';
import { ScenesControlComponent } from './components/controls/scenes-control/scenes-control.component';
import { DeliveryItemComponent } from './components/delivery-item/delivery-item.component';
import { FaqComponent } from './components/faq/faq.component';
import { HeaderComponent } from './components/header/header.component';
import { ImagesComponent } from './components/images/images.component';
import { MainChatComponent } from './components/main-chat/main-chat.component';
import { ObjCalendarComponent } from './components/obj-calendar/obj-calendar.component';
import { OrderItemComponent } from './components/order-item/order-item.component';
import { PersonalPlanningComponent } from './components/personal-planning/personal-planning.component';
import { ProductCategoriesComponent } from './components/product-categories/product-categories.component';
import { ProductItemComponent } from './components/product-item/product-item.component';
import { ProductSearchbarComponent } from './components/product-searchbar/product-searchbar.component';
import { ProductCategoryComponent } from './components/productcategory-card/productcategory-card.component';
import { ProductsCarouselComponent } from './components/products-carousel/products-carousel.component';
import { WorkflowItemComponent } from './components/workflow-item/workflow-item.component';
import { WorkflowSuggestionsComponent } from './components/workflow-suggestions/workflow-suggestions.component';
import { FaIconDirective } from './fa-icon.directive';
import { LoaderService } from './loader-service.service';
import { MessageService } from './message.service';
import { MomentFormatPipe } from './moment-format.pipe';
import { MyBusinessStoreService } from './mybusiness.store';
import { PrettyNmbrPipe } from './pretty-nmbr/pretty-nmbr.pipe';
import { SecondsCountdownPipe } from './seconds-timer.pipe';
import { SentenceCasePipe } from './sentence-case/sentence-case.pipe';
import { RmSpacePipe } from './space/space.pipe';
import { SessionStrIntPipe, strEvalPipe, strIntPipe } from './str-eval.pipe';
import { AppTranslatePipe } from './translate.pipe';
import { UtilService } from './util.service';


@NgModule({
  declarations: [
    // PIPES
    MomentFormatPipe,
    SessionStrIntPipe,
    AppTranslatePipe,
    strIntPipe,
    strEvalPipe,
    RmSpacePipe,
    SecondsCountdownPipe,
    SentenceCasePipe,
    PrettyNmbrPipe,

    // DIRECTIVES
    FaIconDirective,

    // COMPONENTS
    ControlsComponent,
    ControlCategoryComponent,
    ClimateControlComponent,
    LightControlComponent,
    BlindControlComponent,
    DoorControlComponent,
    ScenesControlComponent,
    BikeControlComponent,

    ButtonGroupComponent,

    DeliveryItemComponent,
    ProductItemComponent,
    HeaderComponent,
    ProductCategoryComponent,
    ProductsCarouselComponent,
    PersonalPlanningComponent,
    WorkflowSuggestionsComponent,
    ComponentsComponent,
    FaqComponent,
    MainChatComponent,
    ChatsComponent,
    AnnouncementsComponent,
    ChatItemComponent,
    ImagesComponent,
    ProductCategoriesComponent,
    ProductSearchbarComponent,
    AnnouncementItemComponent,
    WorkflowItemComponent,
    OrderItemComponent,
    ObjCalendarComponent,

  ],
  exports: [
    // PIPES
    MomentFormatPipe,
    SessionStrIntPipe,
    AppTranslatePipe,
    strIntPipe,
    strEvalPipe,
    RmSpacePipe,
    SecondsCountdownPipe,
    SentenceCasePipe,
    PrettyNmbrPipe,


    // DIRECTIVES
    FaIconDirective,

    // COMPONENTS
    ControlsComponent,
    ControlCategoryComponent,
    ClimateControlComponent,
    LightControlComponent,
    BlindControlComponent,
    DoorControlComponent,
    ScenesControlComponent,
    BikeControlComponent,

    ButtonGroupComponent,

    DeliveryItemComponent,
    ProductItemComponent,
    HeaderComponent,
    ProductCategoryComponent,
    ProductsCarouselComponent,
    PersonalPlanningComponent,
    WorkflowSuggestionsComponent,
    ComponentsComponent,
    FaqComponent,
    MainChatComponent,
    ChatsComponent,
    AnnouncementsComponent,
    ChatItemComponent,
    ImagesComponent,
    ProductCategoriesComponent,
    ProductSearchbarComponent,
    AnnouncementItemComponent,
    WorkflowItemComponent,
    OrderItemComponent,
    ObjCalendarComponent,
  ],
  providers: [
    LoaderService,
    MessageService,
    MyBusinessStoreService,
    UtilService,
  ],
  imports: [
    CommonModule,
    ResourceModule,
    RouterModule,
    SwiperModule,
    TranslateModule.forChild(),
    IonicModule,
  ]
})
export class CoreModule { }
