<div *ngIf="('controls_intro_header'| appTranslate) !== 'controls_intro_header' || ('controls_intro_message'| appTranslate) !== 'controls_intro_message'" class="ion-padding ion-text-center">
  <div *ngIf="('controls_intro_header'| appTranslate) !== 'controls_intro_header'" class="header" [innerHTML]="'controls_intro_header'| appTranslate"></div>
  <div *ngIf="('controls_intro_message'| appTranslate) !== 'controls_intro_message'" class="description ion-text-center ion-padding-horizontal" [innerHTML]="'controls_intro_message'| appTranslate"></div>
</div>

<div class="ion-padding-top">
  <div style="display: grid; grid-template-columns: 1fr; gap: var(--ion-padding); padding:var(--ion-padding)">
    @for (item of groups; track item._id) {
    <app-control-category [item]="item"></app-control-category>
    } @empty {
    <div class="no-results-message">
      {{(settings.noResultsMessage || 'No items')| appTranslate}}

    </div>
    <div class="ion-padding-vertical ion-text-center">
      <div class="description ion-padding-bottom">
        {{'controls_emergency_phone_text'| appTranslate}}
      </div>
      <ion-button fill="solid" (click)="callEmergencyPhone()">
        <ion-icon slot="start" [faIcon]="'phone'"></ion-icon>
        <ion-label>
          {{'controls_emergency_phone_label'| appTranslate}}
        </ion-label>
      </ion-button>
    </div>
    }
  </div>
</div>