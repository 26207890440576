import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-productcategory',
  templateUrl: './productcategory-card.component.html',
  styleUrls: ['./productcategory-card.component.scss'],
})
export class ProductCategoryComponent implements OnInit {
  @Input() item: any;
  @Input() link: any;
  @Input() lines: any;
  @Input() button: any;
  @Input() size: string;
  @Input() linkQueryParams;

  constructor() { }

  ngOnInit() {
    // this.item.products = 0;
  }

  get levelClass() {
    switch (true) {
      case !!this.item.webshopcategory6:
        return 'cat-6';
      case !!this.item.webshopcategory5:
        return 'cat-5';
      case !!this.item.webshopcategory4:
        return 'cat-4';
      case !!this.item.webshopcategory3:
        return 'cat-3';
      case !!this.item.webshopcategory2:
        return 'cat-2';
      case !!this.item.webshopcategory1:
        return 'cat-1';
    }
    return '';
  }
}
