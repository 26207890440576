import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'sentenceCase'
})
export class SentenceCasePipe implements PipeTransform {
  transform(value: string, args?: any): any {
    if (value) {
      const [first, ...remain] = value.split('');
      return first.toString().toUpperCase() + remain.map(s => s.toLowerCase()).join('');
    }
  }
}