import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IAnnoucement } from 'src/app/api/announcement/announcement.resource';
import { AnnoucementModalComponent } from '../../modals/annoucement-modal/annoucement-modal.component';
import { AnnouncementService } from 'src/app/api/announcement/announcement.service';

@Component({
  selector: 'app-announcement-item',
  templateUrl: './announcement-item.component.html',
  styleUrls: ['./announcement-item.component.scss'],
})
export class AnnouncementItemComponent implements OnInit {
  @Input() item: IAnnoucement;
  @Input() link: any;
  @Input() lines: any;
  @Input() button: any;
  @Input() objectkey;
  notFound;

  constructor(
    private changeRef: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private service: AnnouncementService
  ) { }

  async ngOnInit() {
    try {
      if (!this.item) {
        if (this.objectkey) {
          this.item = await this.service.getOne(parseFloat(this.objectkey));
          console.log(this.item);
        } else {
          this.notFound = true;
        }
      }
    } catch (error) {
      this.notFound = true;
    } finally {
      this.changeRef.detectChanges();
    }
  }

  async open() {
    (await this.modalCtrl.create({
      breakpoints: [1],
      initialBreakpoint: 1,
      handle: false,
      cssClass: 'variable-height',
      backdropDismiss: this.item.letterkind !== 'policy',
      component: AnnoucementModalComponent,
      componentProps: { announcement: this.item, canDismiss: true }
    })).present();

  }

}
