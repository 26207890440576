@for (comp of components; track $index) {
<ng-container *ngIf="!comp.showCondition || (comp.showCondition | strEval:{ mybusiness, reservation })">
  <ion-list-header *ngIf="comp.header && !comp.hideHeader">
    <ion-label>
      <div class="header large">
        <span [innerHTML]="comp.header.title | sessionStrInt| appTranslate"></span>
        <ion-icon *ngIf="comp.header.infoWorkflowNmbr" color="dark" style="width:12px; height:12px; margin-left:10px; cursor: pointer;" [faIcon]="'circle-info'"></ion-icon>
      </div>
    </ion-label>
    <ion-icon style="margin-right:12px; height:20px; width:20px;" *ngIf="comp.header.icon" [faIcon]="'' + comp.header.icon"></ion-icon>
  </ion-list-header>
  <ng-container [ngSwitch]="comp.type">
    <app-products-carousel [context]="context" [refreshSubject]="refreshSubject" *ngSwitchCase="'PRODUCTS_CAROUSEL'" [config]="comp"></app-products-carousel>
    <app-personal-planning [context]="context" [refreshSubject]="refreshSubject" *ngSwitchCase="'PERSONAL_PLANNING'" [config]="comp"></app-personal-planning>
    <app-workflow-suggestions [context]="context" [refreshSubject]="refreshSubject" *ngSwitchCase="'WORKFLOW_SUGGESTIONS'" [config]="comp"></app-workflow-suggestions>
    <app-faq [context]="context" [refreshSubject]="refreshSubject" *ngSwitchCase="'FAQ'" [config]="comp"></app-faq>
    <app-main-chat [context]="context" [refreshSubject]="refreshSubject" *ngSwitchCase="'MAIN_CHAT'" [config]="comp"></app-main-chat>
    <app-chats [context]="context" [refreshSubject]="refreshSubject" *ngSwitchCase="'CHATS'" [config]="comp" (configChange)="comp.hideHeader = $event.hideHeader;"></app-chats>
    <app-announcements [context]="context" [refreshSubject]="refreshSubject" *ngSwitchCase="'ANNOUNCEMENTS'" [config]="comp"></app-announcements>
    <app-images [context]="context" [refreshSubject]="refreshSubject" *ngSwitchCase="'IMAGES'" [config]="comp"></app-images>
    <app-product-categories [context]="context" [refreshSubject]="refreshSubject" *ngSwitchCase="'PRODUCT_CATEGORIES'" [config]="comp"></app-product-categories>
    <app-product-searchbar [context]="context" [refreshSubject]="refreshSubject" *ngSwitchCase="'PRODUCT_SEARCHBAR'" [config]="comp"></app-product-searchbar>
    <app-controls [context]="context" [refreshSubject]="refreshSubject" *ngSwitchCase="'CONTROLS'" [config]="comp"></app-controls>
  </ng-container>
</ng-container>
}