import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IComponentConfig } from '../components/components.component';
import { AnnouncementService } from 'src/app/api/announcement/announcement.service';
import { Subject } from 'rxjs';

interface IAnnouncementsSettings {
  noResultsMessage?: string;
  query?; any;
  moreBtn: {
    label?: string;
    icon?: string;
  }
}

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementsComponent implements OnInit {
  @Input() config: IComponentConfig<IAnnouncementsSettings>;
  @Input() refreshSubject: Subject<any>;
  @Input() context;


  items: any[] = [];

  total = 0;
  visible = 0;
  constructor(
    private service: AnnouncementService,
    private changeRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (this.refreshSubject) {
      this.refreshSubject.subscribe(() => {
        this.refreshData();
      });
    }
  }


  async refreshData() {
    const res = await this.service.query({ pageamount: 4, page: 1, ...this.config.settings.query });
    this.total = res.total;
    this.items = res.results;
    this.visible = this.items.length;
    this.changeRef.detectChanges();
  }

  get settings(): IAnnouncementsSettings {
    return this.config.settings;
  }

}
