import { Injectable } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { IAuth, SessionResourceService } from './session.resource';
import { SessionStoreService } from './session.store';
import { LoaderService } from '../../core/loader-service.service';
import { Router } from '@angular/router';
import { ReservationStoreService } from '../reservation/reservation.store';
import { ReservationService } from '../reservation/reservation.service';
import OneSignal from 'onesignal-cordova-plugin';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(
    private resource: SessionResourceService,
    private store: SessionStoreService,
    private reservation: ReservationStoreService,
    private reservationService: ReservationService,
    private loaderService: LoaderService,
    private navCtrl: NavController,
    private router: Router,
    private platform: Platform,

  ) { }

  private setOneSignalUserId() {
    this.platform.ready().then(() => {
      if ((window as any)?.cordova) {
        OneSignal.login('PER-' + this.store.erpUID);
      }
    });
    this.refreshUserSettings();
    Sentry.setUser(this.store.userData);
  }

  private removeOneSignalUserId() {
    this.platform.ready().then(() => {
      if ((window as any)?.cordova) {
        OneSignal.logout()
      }
    });
    Sentry.setUser(null);

  }

  getRefreshToken(): string | string[] {
    return this.store.refreshToken;
  }

  getAccessToken(): string | string[] {
    return this.store.accessToken;
  }

  async refreshSession() {
    await this.loaderService.start();
    try {
      const session = await this.resource.check();
      this.store.auth = session;
      this.setOneSignalUserId();
      this.refreshUserSettings();
    } catch (error) {
      throw error;
    } finally {
      this.loaderService.stop();
    }
  }


  async refreshToken(): Promise<IAuth> {
    const auth = await this.resource.refresh()
    this.store.auth = auth;
    return auth;
  }

  async refreshUserSettings() {
    let settings = await this.resource.getUserSettings();
    this.store.settings = settings;
  }

  clearSession() {
    this.store.clear();
    this.removeOneSignalUserId();
  }

  async logIn(credentials: { username: string, password: string }): Promise<any> {
    await this.loaderService.start();
    this.clearSession();
    try {
      const auth = await this.resource.logIn(credentials)
      this.store.auth = auth;
      this.setOneSignalUserId();
      await this.refreshUserSettings()
    } catch (error) {
      throw error;
    } finally {
      this.loaderService.stop();
    }
  }

  async logOut() {
    await this.loaderService.start();
    try {
      this.resource.logOut();
    } catch (error) {
      throw error;
    } finally {
      this.loaderService.stop();
    }
    this.clearSession();
    if (window.location.pathname !== '/activate') {
      this.navCtrl.navigateRoot('/login');
    }
  }

  async subscribeNewsletter() {
    await this.loaderService.start();
    try {
      await this.resource.subscribeNewsletter();
      await this.refreshSession();
    } catch (error) {
      throw error;
    } finally {
      this.loaderService.stop();
    }
  }

  async unsubscribeNewsletter() {
    await this.loaderService.start();
    try {
      await this.resource.unsubscribeNewsletter();
      await this.refreshSession();
    } catch (error) {
      throw error;
    } finally {
      this.loaderService.stop();
    }
  }

  async unlinkReservation() {
    await this.loaderService.start();
    try {
      await this.resource.unlinkReservation();
      this.navCtrl.navigateRoot('/activate');
    } catch (error) {
      throw error;
    } finally {
      this.loaderService.stop();
    }
  }

  async removeAccount() {
    await this.loaderService.start();
    try {
      await this.resource.removeAccount();
      this.clearSession();
      this.navCtrl.navigateRoot('/login');
    } catch (error) {
      throw error;
    } finally {
      this.loaderService.stop();
    }
  }

  sendEmailVerification() {
    return this.resource.sendVerification();
  }

  requestResetPassword(email) {
    return this.resource.requestResetPassword({ email })
  }

  getResetHash(email, code) {
    return this.resource.getResetHash({ email, code });
  }

  resetPassword(obj) {
    return this.resource.resetPassword(obj)
  }



  async goToLandingPage() {
    try {
      await this.reservationService.refreshCurrentStored();
    } catch (error) { }
    if (!this.reservation.hasReservation) {
      this.router.navigate(['/activate']);
    } else if (this.reservation.reservationHasStarted || this.reservation.daysTillReservation <= this.store.settings?.permissions?.activateAppDaysBeforeReservation) {
      window.localStorage.removeItem('RESERVATION_INPUT');
      this.router.navigate(['/base/tab1'])
    } else {
      this.router.navigate(['/wait'])
    }
  }
}
