import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSearchbar } from '@ionic/angular';
import { Subject } from 'rxjs';
import { WorkflowService } from 'src/app/api/workflow/workflow.service';
import { IComponentConfig } from '../components/components.component';

interface IFaqSettings {
  noResultsMessage: string;
  query?: any;
  moreBtn: {
    label: string;
    icon?: string;
  }

}

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {

  @Input() config: IComponentConfig<IFaqSettings>;
  @Input() refreshSubject: Subject<any>;
  @Input() context;


  @ViewChild('searchbar') searchbar: IonSearchbar

  items: { value: string, quantity: number }[] = [];

  searchValue = '';

  total = 0;
  visible = 0;

  constructor(
    private service: WorkflowService,
    private changeRef: ChangeDetectorRef,
    private router: Router,
  ) { }

  ngOnInit() {
    if (this.refreshSubject) {
      this.refreshSubject.subscribe(() => {
        this.refreshData();
      });
    }
  }


  async refreshData() {
    const res = await this.service.queryCategory({ pageamount: 4, page: 1 });
    this.total = res.total;
    this.items = res.results;
    this.visible = this.items.length;
    this.changeRef.detectChanges();
  }


  get settings(): IFaqSettings {
    return this.config.settings;
  }


  async doSearch(value) {
    await this.router.navigate(['/base/workflows/search'], {
      queryParams: {
        search: value
      }
    });
    this.searchbar.value = '';
  }

}
