import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { IDelivery } from 'src/app/api/order/order.resource';
import { OrderService } from 'src/app/api/order/order.service';
import { IProduct } from 'src/app/api/product/product.resource';
import { ProductService } from 'src/app/api/product/product.service';
import { debounce } from '../../debounce.helper';
import { LoaderService } from '../../loader-service.service';
import { MessageService } from '../../message.service';
import { IComponentConfig } from '../components/components.component';

interface IPersonalPlanningSettings {
  amountOfDaysAhead?: number,
  noResultsMessage?: string,
  productquery?: any,
  moreBtn?: {
    icon?: string,
    sref?: string[]
    label: string,
    link: any[]
  } | false

}
@Component({
  selector: 'app-personal-planning',
  templateUrl: './personal-planning.component.html',
  styleUrls: ['./personal-planning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalPlanningComponent implements OnInit {
  @Input() config: IComponentConfig<IPersonalPlanningSettings>;
  @Input() refreshSubject: Subject<any>;
  @Input() context;

  public buttons: {
    label: string;
    date: number;
  }[] = [];

  dbRefreshData;

  isLoading = false;


  public deliveries: IDelivery[] = [];
  private suggestionResutls: IProduct[] = [];

  private featuredProducts: string[] = [];

  constructor(
    private changeRef: ChangeDetectorRef,
    private productService: ProductService,
    private orderService: OrderService,
    private messageService: MessageService,
    private loaderService: LoaderService,
  ) {
    this.dbRefreshData = debounce(() => {
      this.refreshData();
    }, 500);
  }

  get disableAdd() {
    return moment().isAfter(this.context.selectedDate, 'date')
  }

  get settings(): IPersonalPlanningSettings {
    return this.config.settings;
  }

  get amountOfDaysAhead() {
    return this.settings.amountOfDaysAhead || 3;
  }

  get suggestions() {
    return this.suggestionResutls;
    return this.suggestionResutls.filter(p => this.featuredProducts.indexOf(p.productkey) === -1);
  }

  ngOnInit() {
    if (this.refreshSubject) {
      this.refreshSubject.subscribe(() => {
        this.refreshData();
      });
    }

    this.messageService.subscribe('products-carousel.refresh', (data: { products: IProduct[] }) => {
      this.featuredProducts = data.products.map(p => p.productkey);
    })
  }

  async refreshData() {
    this.isLoading = true;
    this.changeRef.detectChanges();
    try {
      this.deliveries = [];
      this.suggestionResutls = [];
      this.changeRef.detectChanges();
      this.deliveries = (await this.orderService.queryDelivery({
        'orderdeliveries.products': '>0',
        skipLoader: true,
        'orderdeliveries.deliverydate': '>' + (moment(this.context.selectedDate).startOf('day').unix() * 1000) + ';<' + (moment(this.context.selectedDate).endOf('day').unix() * 1000),
        disablepaging: true
      })).results;
      if (moment(this.context.selectedDate).isSameOrAfter(Date.now(), 'date')) {
        this.suggestionResutls = (await this.productService[this.context.selectedDate ? 'queryDate' : 'query']({
          ...this.settings.productquery,
          'selecteddate': this.context.selectedDate,
          skipLoader: true,
          'products.productkey': this.deliveries.map(del => del?.mainProduct?.productkey).filter(a => a).map(a => '!' + a).join(';'),
        })).results;
        this.changeRef.detectChanges();
      } else {
        this.suggestionResutls = [];
      }
      this.messageService.broadcast('personal-planning.refresh', {
        suggestions: this.suggestionResutls,
        deliveries: this.deliveries,
        selectedDate: this.context.selectedDate,
      });


      this.changeRef.detectChanges();

    } catch (error) { } finally { }
    this.isLoading = false;
    this.changeRef.detectChanges();

  }

  gotoToday() {
    this.context.selectedDate = moment().unix() * 1000;
  }

  trackDelivery(a, b) {
    return b.deliverynmbr;
  }

  trackProduct(a, b) {
    return b.productkey;
  }

}
