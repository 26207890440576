import { Injectable } from '@angular/core';
import { IResourceMethodPromise, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod, ResourceResponseBodyType } from '@ngx-resource/core';


export interface IAuth {
  accesstoken: string;
  refreshtoken: string;
  user: {
    emailVerified: boolean
    resetHash: string,
    resetExpireDate: Date,
  }
  guest: {
    erpUID: string,
    erpPersonUID: string,
    firstname: string,
    lastname: string,
    activeReservationId: string,
    erpWorkgroup: string,
  },
  employee: {
    erpUID: string,
    erpWorkgroup: string,
    erpPersonUID: string,
    introduction: string,
    firstname: string,
    lastname: string,
  }
  role: string
}

@ResourceParams({
  pathPrefix: '/api/auth'
})
@Injectable({
  providedIn: 'root'
})
export class SessionResourceService extends Resource {

  constructor(handler: ResourceHandler) {
    super(handler);
  }


  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/create'
  })
  logIn: IResourceMethodPromise<{ username: string, password: string }, IAuth>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/check'
  })
  check: IResourceMethodPromise<null, null>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/user-settings'
  })
  getUserSettings: IResourceMethodPromise<null, null>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/sendVerification'
  })
  sendVerification: IResourceMethodPromise<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/checkVerification'
  })
  checkVerification: IResourceMethodPromise<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/requestResetPassword/{!email}'
  })
  requestResetPassword: IResourceMethodPromise<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/getResetHash/{!email}/{!code}'
  })
  getResetHash: IResourceMethodPromise<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/resetPassword'
  })
  resetPassword: IResourceMethodPromise<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    responseBodyType: ResourceResponseBodyType.Text,
    path: '/newsletter/subscribe'
  })
  subscribeNewsletter: IResourceMethodPromise<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    responseBodyType: ResourceResponseBodyType.Text,
    path: '/newsletter/unsubscribe'
  })
  unsubscribeNewsletter: IResourceMethodPromise<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    responseBodyType: ResourceResponseBodyType.Text,
    path: '/unlinkReservation'
  })
  unlinkReservation: IResourceMethodPromise<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Delete,
    responseBodyType: ResourceResponseBodyType.Text,
    path: '/removeAccount'
  })
  removeAccount: IResourceMethodPromise<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/refresh'
  })
  refresh: IResourceMethodPromise<null, IAuth>;

  @ResourceAction({
    path: '/revoke',
    responseBodyType: ResourceResponseBodyType.Text,
    method: ResourceRequestMethod.Delete,
  })
  logOut: IResourceMethodPromise<void, void>;

}
