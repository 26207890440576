import { Injectable } from '@angular/core';
import { Resource, ResourceHandler, ResourceAction, ResourceRequestMethod, IResourceMethodPromise, ResourceResponseBodyType, ResourceParams } from '@ngx-resource/core';
import { IMongooseResult } from '../api.module';

export interface IChat {
  nmbr?: number;
  companynmbr?: number;
  creator?: string;
  chatname?: string;
  timestamp?: number;
  chatstatus?: string;
  object1?: string;
  objectkey?: string;
  chatpriority?: string;
  actionholder?: string;
  menulevel?: number;
  unread?: number;
  maxtimestamp?: number;
  string?: String;
  memberImages?: string[];
  memberNames?: string[];
  companycolor?: string;
  lastparagraph?: string;
  lastcreator?: string;
  lasttimestamp?: number;
  lastobject1?: string;
  lastobjectkey?: string;
  lastchatmembernmbr?: number;
  chatstatustext?: string;
  members?: IChatMember[];
  lastmessage?: ILastChatMessage;
}



export interface ILastChatMessage {
  paragraph?: string;
  creator?: string;
  timestamp?: number;
  object1?: string;
  objectkey?: string;
  chatmembernmbr?: number;
  personalinfo?: { firstname: string }
}

export interface IChatMember {
  nmbr?: number;
  chatnmbr?: number;
  companynmbr?: number;
  employee?: string;
  chatmembermode?: string;
  chatrelation?: string;
  person?: string;
  notificationmode?: string;
  workgroup?: String;
  personalinfo?: IPersonalinfo;
}

export interface IPersonalinfo {
  searchname?: string;
  firstname?: string;
  surname?: string;
  personimage?: string;
  persontxt1?: string;
  persontxt2?: string;
  persontxt3?: string;
  persontxt4?: string;
  persontxt5?: string;
  shortname?: string;
  workgroup?: string;
  lastchatactivitytimestamp?: number;
}
export interface IUploadFile {
  nmbr?: number
  sequence?: string
  modifieddate?: number
  employee?: string
  filename?: string
  filesize?: string
  data: string | ArrayBuffer
}

@ResourceParams({
  pathPrefix: '/api/guest/chat'
})
@Injectable({
  providedIn: 'root'
})
export class ChatResourceService extends Resource {

  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/{!nmbr}'
  })
  getOne: IResourceMethodPromise<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: ''
  })
  query: IResourceMethodPromise<any, IMongooseResult<any>>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/{!nmbr}/status'
  })
  getStatuses: IResourceMethodPromise<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/{!nmbr}/askCuber'
  })
  askCuber: IResourceMethodPromise<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/{!nmbr}/chatmember'
  })
  getChatMembers: IResourceMethodPromise<any, IMongooseResult<any>>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/{!nmbr}/attachment'
  })
  uploadFile: IResourceMethodPromise<IUploadFile, IMongooseResult<any>>;


  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/attachment/{!filename}',
    asPromise: true,
    removeTrailingSlash: true,
    responseBodyType: ResourceResponseBodyType.Blob
  })
  downloadFile: IResourceMethodPromise<IUploadFile, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/{!nmbr}/markAsRead'
  })
  markAsRead: IResourceMethodPromise<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/askForHelp'
  })
  askForHelp: IResourceMethodPromise<any, any>;

}
