import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { ReservationService } from "../reservation/reservation.service";
import { ReservationStoreService } from "../reservation/reservation.store";
import { AnnouncementService } from "./announcement.service";
import { AnnoucementModalComponent } from "src/app/core/modals/annoucement-modal/annoucement-modal.component";
import { SessionStoreService } from "../session/session.store";
import build from '../../env.json';

@Injectable()
export class AnnouncementGuardService implements CanActivate {

  constructor(
    private service: AnnouncementService,
    private reservationService: ReservationService,
    private reservationStore: ReservationStoreService,
    private sessionStore: SessionStoreService,
    private modalCtrl: ModalController) { }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      await this.reservationService.refreshCurrentStored();
    } catch (error) {
      return true;
    }
    const daysTillAppAvailable = this.reservationStore.daysTillReservation - 5;
    if (daysTillAppAvailable <= 0) {
      const page = build.name + '-' + this.getDynamicPageName(state.url) || (route.data['pageName'] as string || '').toLowerCase();
      const localStoreKey = 'annoucementCheckTimestamp.' + (page || 'global');
      const prevCheck = parseInt(localStorage.getItem(localStoreKey) || '0', 10) + ((1000 * 60) * 0) // add 1 minutes;

      if (Date.now() > prevCheck) {
        try {
          const list = await this.service.getNew();
          for (const announcement of list.filter(a => this.strEqual(a.letterkind, build.name + '-policy') || this.strEqual(page, a.letterkind) || a.letterkind === '')) {
            const inst = await this.modalCtrl.create({
              breakpoints: [1],
              initialBreakpoint: 1,
              handle: false,
              cssClass: 'variable-height',
              backdropDismiss: !(announcement.letterkind || '').toLowerCase().endsWith('policy'),
              component: AnnoucementModalComponent,
              componentProps: { announcement }
            });
            await inst.present();
            await inst.onDidDismiss();
          }
          localStorage.setItem(localStoreKey, Date.now().toString());
        } catch (error) { }
      }
    }
    return true;
  }

  strEqual(a, b) {
    return (a || '').toLowerCase() === (b || '').toLowerCase();
  }

  getDynamicPageName(url): string {
    const menu = this.sessionStore?.settings?.mainMenu;
    try {
      const parsedUrl = url.replace(/\/base\//gi, '');
      if (parsedUrl.startsWith('tab')) {
        const tabIndex = (parseInt(parsedUrl.replace(/tab/gi, ''), 10) - 1);
        return menu[tabIndex].name;
      } else {
      }
    } catch (error) {
    }
    return '';
  }
}