
import {
  Animation,
  createAnimation,
} from '@ionic/core';

export const TRANSITION_HORIZONTAL = 'attr.data-transition-horizontal';
export const ROUTER_TRANSITION_HORIZONTAL = 'data-transition-horizontal';

export class CustomAnimations {

  constructor() { }
  navAnimation(navEl: any, opts: any): Animation {
    const DURATION = 200;

    const rootTransition = createAnimation()
      .duration(opts.duration || 333)
      .easing('cubic-bezier(0.7,0,0.3,1)');

    const enter = createAnimation()
      .addElement(opts.enteringEl);

    const leave = createAnimation()
      .addElement(opts.leavingEl);

    enter.fromTo('opacity', '0', '1');
    leave.fromTo('opacity', '1', '0');

    enter.duration(DURATION);
    leave.duration(DURATION);

    return rootTransition
      .addAnimation(enter)
      .addAnimation(leave);
  }

}
