import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { default as nl } from '@angular/common/locales/nl';
import { APP_INITIALIZER, ErrorHandler, Injector, LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SplashScreen } from '@capacitor/splash-screen';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { NgxPicaModule } from '@digitalascetic/ngx-pica';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ResourceActionReturnType, ResourceGlobalConfig, ResourceHandler, ResourceModule, ResourceQueryMappingMethod } from '@ngx-resource/core';
import { ResourceHandlerHttpClient } from '@ngx-resource/handler-ngx-http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular-ivy';
import moment from 'moment';
import { NgxImageCompressService } from 'ngx-image-compress';
import SwiperCore, { A11y, Autoplay, Controller, FreeMode, Navigation, Pagination, Parallax, Scrollbar, Thumbs, Virtual, Zoom } from 'swiper';
import { SwiperModule } from 'swiper/angular';
import { AnnouncementGuardService } from './api/announcement/announcement.guard';
import { SessionInterceptor } from './api/session-interceptor.service';
import { NonSessionGuardService, SessionGuardService } from './api/session/session.guard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appInitializerFactory } from './appInitializerFactory';
import { DamageBikeReportModalComponent } from './core/components/controls/bike-control/damage-report-modal/damage-report-modal.component';
import { CoreModule } from './core/core.module';
import { AnnoucementModalComponent } from './core/modals/annoucement-modal/annoucement-modal.component';
import { PayModalComponent } from './core/modals/pay-modal/pay-modal.component';
import { ProductTermsModalComponent } from './core/modals/product-terms/product-terms-modal.component';
import { PermissionsResolver, StaticPageConfigResolver } from './core/static-page-config.resolver';
import { AppTranslateService } from './core/translate.service';
import { CustomAnimations } from './custom-animations';
import ENV from './env.json';


// todo week start on monday instead of sunday...
moment.locale('en_EN');

SplashScreen.show();

CapacitorUpdater.addListener('appReady', () => {
  SplashScreen.hide();
});


CapacitorUpdater.notifyAppReady();
if (ENV.sentryDsn) {
  Sentry.init({
    // enabled: !window.location.origin.startsWith('http://localhost'),
    enabled: false,
    release: ENV.version + ' ' + ENV.build,
    dsn: ENV.sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /(http|https):\/\/(.*).mybusiness.nl/],
      }),
      new Sentry.Replay({
        blockAllMedia: true,
        maskAllText: false
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: .5, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: .1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: [
      /NG02100/mgi,
      /"status":423/mgi,
      /"status":400/mgi,
      /"status":401/mgi,
      /"status":404/mgi,
      /'isProxied'/mgi
    ]
  });
}

registerLocaleData(nl);

const customAnimations = new CustomAnimations();

ResourceGlobalConfig.returnAs = ResourceActionReturnType.Promise;
ResourceGlobalConfig.queryMappingMethod = ResourceQueryMappingMethod.None;
ResourceGlobalConfig.url = ENV.appBackend;
const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, ENV.appBackend + '/api/translate/', '');


SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Parallax,
  Thumbs,
  FreeMode,
  Controller
]);


@NgModule({
  declarations: [
    AppComponent,
    AnnoucementModalComponent,
    DamageBikeReportModalComponent,
    PayModalComponent,
    ProductTermsModalComponent,
  ],
  imports: [
    BrowserModule,
    SwiperModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgxPicaModule,
    CoreModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    IonicModule.forRoot({
      backButtonIcon: ENV.appBackend + '/assets/fa/regular/chevron-left.svg',
      navAnimation: customAnimations.navAnimation,
      backButtonText: '',
      statusTap: true,
      swipeBackEnabled: false,
      menuIcon: ENV.appBackend + '/assets/fa/regular/bars.svg',
      mode: 'md',

    }),
    AppRoutingModule,
    HttpClientModule,
    ResourceModule.forRoot({
      handler: {
        provide: ResourceHandler,
        useClass: ResourceHandlerHttpClient,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    ...(ENV.sentryDsn ? [
      { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false, }) },
      { provide: Sentry.TraceService, deps: [Router], },
      { provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [Sentry.TraceService], multi: true },
    ] : []),
    ...[
      { provide: LOCALE_ID, useValue: 'nl-NL' },
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
      OneSignal,
      SessionGuardService,
      NonSessionGuardService,
      AppTranslateService,
      PermissionsResolver,
      NgxImageCompressService,
      AnnouncementGuardService,

      { provide: APP_INITIALIZER, useFactory: appInitializerFactory, deps: [TranslateService, Injector], multi: true },
      StaticPageConfigResolver,
    ]],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule { }
