@if (item) {
<ion-item [ngClass]="{'isSuggestion': !!isSuggestion}" style="--min-height: 85px;" [lines]="lines" [button]="button" [queryParams]="linkQueryParams" [routerLink]="link">
  <div slot="start" style="position: relative;" *ngIf="image !== 'none' && item?.productimage">
    <div style="position: absolute; top:0; left:0; width:100%; height:100%; background-color: rgba(var(--ion-background-color-rgb), .5); backdrop-filter: blur(2px);" *ngIf="item.disabled">
      <ion-text color="primary" style="position: absolute; top:50%; left:50%; transform: translate(-50%, -50%);">
        <div class="header small featured ion-text-center">
          Sold Out
        </div>
      </ion-text>
    </div>
    <ion-img slot="start" [src]="item.productimage"></ion-img>
  </div>
  <div class="myb-flex">
    @if (isSuggestion) {
    <div class="wrapper horizontal" *ngIf="bookTimeFormatted || availableSpots > 0">
      <div data-swiper-parallax="-10" class="content">
        <div id="metadescription" class="featured description text-small">
          <ion-text color="primary">
            <span [innerHTML]="bookTimeFormatted"></span>


            <span *ngIf="availableSpots > 0">
              &nbsp;•&nbsp;

              @if (availableSpots > 6) {
              {{'many_cource_available_label'| appTranslate | sentenceCase | lowercase}}
              } @else {
              {{availableSpots}} {{'few_cource_available_label'| appTranslate | sentenceCase | lowercase}}
              }

            </span>
          </ion-text>
        </div>
      </div>
    </div>
    } @else {
    <div data-swiper-parallax="-10" *ngIf="bookTimeFormatted" class="wrapper horizontal">
      <div class="content">
        <div id="metadescription" class="featured description text-small">
          <ion-text color="medium">
            <span *ngIf="showBookDate">{{bookDateLabel | sentenceCase}}&nbsp;•&nbsp;</span>
            <span [innerHTML]="bookTimeFormatted"></span>
          </ion-text>
        </div>
      </div>
    </div>
    }
    <div data-swiper-parallax="-40" *ngIf="!bookTimeFormatted" class="wrapper horizontal">
      <div class="content">
        <div id="metadescription" class="description featured ion-clamp-1" [innerHTML]="item?.metadescription"></div>
      </div>
    </div>
    <div data-swiper-parallax="-30" *ngIf="item.productname" class="wrapper horizontal">
      <div class="content">
        <div id="productname" class="header ion-clamp-3" [innerHTML]="item.productname"></div>
      </div>
    </div>
    <div data-swiper-parallax="-40" *ngIf="bookTimeFormatted" class="wrapper horizontal">
      <div class="content">
        <div id="metadescription" class="description ion-clamp-1" [innerHTML]="item?.metadescription"></div>
      </div>
    </div>
    <div data-swiper-parallax="-50" class="wrapper horizontal">
      <div class="content">
        <div id="metadescription" class="description ion-clamp-1" [innerHTML]="item?.productspec"></div>
      </div>
    </div>

    <div data-swiper-parallax="-60" *ngIf="item.productprice && !isSuggestion" class="wrapper horizontal">
      <div class="content">
        <div class="header" style="color:var(--ion-color-primary); margin: 0px !important;" id="productprice" [innerHTML]="item.productprice | currency:'EUR' | rmspace"></div>
      </div>
      <ion-chip slot="end" color="primary" *ngIf="availableSpots > 0">
        @if (availableSpots > 6) {
        {{'many_cource_available_label'| appTranslate | sentenceCase | lowercase}}
        } @else {
        {{availableSpots}} {{'few_cource_available_label'| appTranslate | sentenceCase | lowercase}}
        }
      </ion-chip>
    </div>
  </div>
  <ion-icon data-swiper-parallax="-70" *ngIf="isSuggestion; else noSuggestion" slot="end" color="primary" [faIcon]="'plus'"></ion-icon>
  <ng-template #noSuggestion>
    <!-- <ion-icon slot="end" color="primary" [faIcon]="'chevron-right'"></ion-icon> -->
  </ng-template>

</ion-item>

} @else {
<ion-item style="position: relative; width: 100%;">
  <div *ngIf="notFound" class="frosted-overlay" style="display: flex; position: absolute; top:0px; left:0px; align-items: center; justify-content: center;">
    <div class="header" style="background: var(--ion-card-background); padding:.6em 1em; border-radius: 20px; white-space:nowrap">
      <ion-icon faIcon="trash"></ion-icon>
      {{'Removed'| appTranslate}}
    </div>
  </div>
  <ion-label style="padding: 0px 7px;">
    <h4 style=" font-weight:600!important;">
      <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
    </h4>
    <div>
      <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
    </div>
  </ion-label>
</ion-item>
}