import { Injectable } from '@angular/core';
import { ServiceBase } from 'src/app/core/service.base';
import { UtilService } from 'src/app/core/util.service';
import { IQueryParams } from './../api.module';
import { IAddDelivery, IDelivery, OrderResourceService } from './order.resource';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends ServiceBase {

  constructor(
    private resource: OrderResourceService,
    private utilService: UtilService,
  ) {
    super('OrderService');
  }


  getOne(nmbr: number) {
    if (!nmbr) {
      this.utilService.broadcastError(this.getCtx('getOne'), 'error_missing_order_nmbr', true);
    }
    return this.utilService.handleServerCall(this.getCtx('getOne'), async () => await this.resource.getOne({ nmbr }));
  }

  getOpen(query?: { orCreate?: boolean }) {
    return this.utilService.handleServerCall(this.getCtx('getOpen'), async () => await this.resource.getOpen(query));
  }

  getEmpty() {
    return this.utilService.handleServerCall(this.getCtx('getOpen'), async () => await this.resource.getEmpty());
  }

  query(query: IQueryParams) {
    return this.utilService.handleServerCall(this.getCtx('query'), async () => await this.resource.query(query));
  }

  queryDelivery(query: IQueryParams) {
    return this.utilService.handleServerCall(this.getCtx('queryDelivery'), async () => await this.resource.queryDelivery(query));
  }

  queryDeliveriesToday() {
    return this.utilService.handleServerCall(this.getCtx('queryDeliveriesToday'), async () => await this.resource.queryDelivery({
      'orderdeliveries.products': '>0',
      'orderdeliveries.deliverydate': '>' + (moment().startOf('day').unix() * 1000) + ';<' + (moment().endOf('day').unix() * 1000),
      disablepaging: true
    }));
  }

  addDelivery(delivery: IAddDelivery) {
    if (!delivery.ordernmbr) {
      this.utilService.broadcastError(this.getCtx('addDelivery'), 'error_missing_order_nmbr', true);
    }
    return this.utilService.handleServerCall(this.getCtx('addDelivery'), async () => await this.resource.addDelivery(delivery));
  }

  removeDelivery(delivery: IDelivery) {
    if (!delivery.ordernmbr) {
      this.utilService.broadcastError(this.getCtx('removeDelivery'), 'error_missing_ordernmbr', true);
    }
    if (!delivery.deliverynmbr) {
      this.utilService.broadcastError(this.getCtx('removeDelivery'), 'error_missing_deliverynmbr', true);
    }
    return this.utilService.handleServerCall(this.getCtx('removeDelivery'), async () => await this.resource.removeDelivery(delivery));
  }

  updateDeliveryAmount(delivery: IDelivery) {
    if (!delivery.ordernmbr) {
      this.utilService.broadcastError(this.getCtx('updateDeliveryAmount'), 'error_missing_ordernmbr', true);
    }
    if (!delivery.deliverynmbr) {
      this.utilService.broadcastError(this.getCtx('updateDeliveryAmount'), 'error_missing_deliverynmbr', true);
    }
    return this.utilService.handleServerCall(this.getCtx('updateDeliveryAmount'), async () => await this.resource.updateDeliveryAmount(delivery));
  }

  prepairPayment(nmbr: number, method) {
    if (!nmbr) {
      this.utilService.broadcastError(this.getCtx('prepairPayment'), 'error_missing_order_nmbr', true);
    }
    return this.utilService.handleServerCall(this.getCtx('prepairPayment'), async () => await this.resource.prepairPayment({ nmbr, method }));
  }

  refreshRentalWindow(delivery: IDelivery) {
    if (!delivery.ordernmbr) {
      this.utilService.broadcastError(this.getCtx('refreshRentalWindow'), 'error_missing_ordernmbr', true);
    }
    if (!delivery.deliverynmbr) {
      this.utilService.broadcastError(this.getCtx('refreshRentalWindow'), 'error_missing_deliverynmbr', true);
    }
    return this.utilService.handleServerCall(this.getCtx('refreshRentalWindow'), async () => await this.resource.refreshRentalWindow(delivery));
  }

  placeOrder(nmbr: number) {
    if (!nmbr) {
      this.utilService.broadcastError(this.getCtx('placeOrder'), 'error_missing_order_nmbr', true);
    }
    return this.utilService.handleServerCall(this.getCtx('placeOrder'), async () => await this.resource.placeOrder({ nmbr }));
  }

  parkOrder(nmbr: number) {
    if (!nmbr) {
      this.utilService.broadcastError(this.getCtx('parkOrder'), 'error_missing_order_nmbr', true);
    }
    return this.utilService.handleServerCall(this.getCtx('parkOrder'), async () => await this.resource.parkOrder({ nmbr }));
  }

}
