import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { AnnouncementService } from 'src/app/api/announcement/announcement.service';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import ENV from '../../../env.json'

@Component({
  selector: 'app-annoucement-modal',
  templateUrl: './annoucement-modal.component.html',
  styleUrls: ['./annoucement-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnoucementModalComponent implements OnInit, AfterViewInit {
  @Input() announcement;
  @Input() canDismiss;

  paragraphs: any[];
  initialized = false;
  index = 0;
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  appBackend = ENV.appBackend;


  swiperOptions: SwiperOptions = {
    slidesPerView: 1,
    pagination: {
      enabled: true,
      clickable: true,
      type: 'bullets'
    }
  };


  constructor(
    public service: AnnouncementService,
    private modalCtrl: ModalController,
    private sanitizer: DomSanitizer,
    private changeRef: ChangeDetectorRef,
  ) { }

  ngAfterViewInit(): void {
    window.setTimeout(() => {
      this.initialized = true;
      this.changeRef.detectChanges();
    }, 5000)
  }

  get isFist() {
    return this.index === 0;
  }

  get swiperRef() {
    return this.swiper.swiperRef;
  }

  get isLast() {
    return this.index >= ((this.paragraphs || []).length - 1);
  }
  get NeedsAccept() {
    return (this.announcement.letterkind || '').endsWith('policy');
  }

  get isAccepted() {
    return this.announcement.accepted;
  }

  async getParagraphs() {
    const contents = (await this.service.getContents(this.announcement.nmbr));
    this.paragraphs = contents.map(p => ({
      ...p,
      paragraph: this.sanitizer.bypassSecurityTrustHtml(p.paragraph)
    }));
    this.changeRef.detectChanges();
  }

  ngOnInit() {
    this.getParagraphs();
  }

  updateIndex() {
    this.index = this.swiperRef.activeIndex;
    this.changeRef.detectChanges();
  }

  async nextSlide() {
    if (this.isLast) {
      if (!this.isAccepted) {
        await this.service.markAsRead(this.announcement.nmbr);
      }
      this.modalCtrl.dismiss();
    } else {
      this.swiperRef.slideTo(this.index + 1, 500);
    }
    this.changeRef.detectChanges();
  }

  async dismiss() {
    if (!this.isAccepted) {
      await this.service.markAsRead(this.announcement.nmbr);
    }
    this.changeRef.detectChanges();
    this.modalCtrl.dismiss();
  }

  prevSlide() {
    this.swiperRef.slideTo(this.index - 1, 500);
    this.changeRef.detectChanges();
  }

}
