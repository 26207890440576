import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { IReservation } from './reservation.resource';

@Injectable({
  providedIn: 'root'
})
export class ReservationStoreService {


  constructor() { }

  get current(): IReservation {
    return JSON.parse(localStorage.getItem('reservation') || '{}')
  }

  set current(v) {
    if (v) {
      localStorage.setItem('reservation', JSON.stringify(v));
    } else {
      localStorage.removeItem('reservation');
    }
  }

  get hasReservation(): boolean {
    return !!this.current && Object.keys(this.current).length > 0;
  }

  get startDateTime() {
    try {
      return new Date(this.current?.startDateTime).getTime();
    } catch (error) { }
    return undefined;
  }

  get endDateTime() {
    try {
      return new Date(this.current?.endDateTime).getTime();
    } catch (error) { }
    return undefined;
  }

  get pmsUID() {
    try {
      return this.current?.pmsUID;
    } catch (error) { }
    return undefined;
  }

  get accomodation() {
    try {
      return this.current?.accommodations[0];
    } catch (error) { }
    return undefined;
  }

  get chatNmbr() {
    return this.current?.erpChatUID;
  }

  get isProvisional() {
    return this.current?.status <= 10;
  }

  get isArrivalToday() {
    const datetime = this.startDateTime;
    return datetime ? moment().isSame(datetime, 'date') : false;
  }

  get isDepartureToday() {
    const datetime = this.endDateTime;
    return datetime ? moment().isSame(datetime, 'date') : false;
  }

  get controlsAvailable() {
    const datetime = this.current?.controlsActivationDateTime;
    return datetime ? moment().isAfter(datetime) : false;
  }

  get controlsActivationTime() {
    const datetime = this.current?.controlsActivationDateTime;
    return datetime ? moment(datetime).format('HH:mm') : 'Unkown'
  }

  get controlsDeactivationTime() {
    const datetime = this.current?.controlsDeactivationDateTime;
    return datetime ? moment(this.current.controlsDeactivationDateTime).format('HH:mm') : 'Unkown';
  }

  get controlsAreActive() {
    return this.current?.controlsAreActive;
  }


  get hasBikes() {
    return this.bikes?.length > 0;
  }


  get bikes() {
    return this.current?.moveloRentals || [];
  }

  get hasChat() {
    return !!this.current.erpChatUID;
  }

  get reservationHasEnded() {
    const datetime = this.current?.controlsDeactivationDateTime;
    return datetime ? moment().isAfter(datetime) : true;
  }

  get reservationHasEndedYesterday() {
    const datetime = this.current?.controlsDeactivationDateTime;
    return datetime ? moment().isAfter(datetime, 'date') : true;
  }

  get reservationIsPast() {
    const datetime = this.current?.endDateTime;
    return datetime ? moment().subtract(2, 'day').endOf('day').isAfter(datetime) : true;
  }

  get reservationHasNotStarted() {
    const datetime = this.startDateTime;
    return datetime ? moment().isBefore(datetime, 'date') : true;
  }

  get reservationHasStarted() {
    const datetime = this.startDateTime;
    return !(datetime ? moment().isBefore(datetime, 'date') : true);
  }

  get daysTillReservation() {
    const datetime = this.startDateTime;
    if (datetime) {
      return Math.ceil(moment.duration(moment(datetime).startOf('day').diff(moment().startOf('day'))).asDays());
    }
    return undefined;
  }

  get hasAccomodationAccess(): boolean {
    return this.current?.suiteAccess?.hasToken;
  }

}
