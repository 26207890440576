<ion-card [class]="levelClass" [routerLink]="item.products > 0 ? ['/base/products/category', item.nmbr] : null" [queryParams]="linkQueryParams" [button]="true">
  <img style="margin-bottom:-5px;" [src]="item.categoryimage || 'https://placehold.co/400x400'" />
  <div style="position: absolute; top:0; left:0; width:100%; height:100%; background-color: rgba(var(--ion-background-color-rgb), .5); backdrop-filter: blur(2px);" *ngIf="item.products === 0">
    <ion-chip color="light" style="position: absolute; bottom:10px; right:10px;">
      Currently unavailble
    </ion-chip>
  </div>
  <ion-card-content>
    <div class="myb-flex">
      <div class="wrapper horizontal">
        <div class="content">
          <div [class]="size" class="header" [innerHTML]="item?.title || item?.categoryname || item?.name"></div>
          <div [class]="size" class="description featured ion-clamp-2" [innerHTML]="item.shortdescription"></div>
        </div>
      </div>
    </div>
  </ion-card-content>
</ion-card>