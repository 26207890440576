import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(
    private loadingCtrl: LoadingController,
  ) {
    this.dbCall = this.debounce(async () => {
      const loader = await this.loader;
      if (loader && this.needClosing) {
        loader.dismiss();
        loader.remove();
        this.loader = undefined;
      }
    }, 300);
  }

  private get loader(): Promise<any> {
    return (document as any).GLOBAL_LOADER;
  }

  private set loader(input: any) {
    (document as any).GLOBAL_LOADER = input;
  }

  private get dbCall(): () => void {
    return (document as any).GLOBAL_LOADER_DEBOUNCE;
  }

  private set dbCall(input: () => void) {
    (document as any).GLOBAL_LOADER_DEBOUNCE = input;
  }


  private get needClosing(): boolean {
    return (document as any).GLOBAL_LOADER_CLOSING;
  }

  private set needClosing(input: boolean) {
    (document as any).GLOBAL_LOADER_CLOSING = input;
  }

  async start() {
    this.dbCall();
    this.needClosing = false;
    if (!this.loader) {
      this.loader = this.loadingCtrl.create();
    }
    const loader = await this.loader;
    if (loader) {
      loader.present();
    }
  }

  async stop() {
    this.dbCall();
    this.needClosing = true;
  }

  debounce(callback: () => any, wait: any, context = this) {
    let timeout: any = null;
    let callbackArgs: any = null;
    const later = () => callback.apply(context, callbackArgs);
    return function () { callbackArgs = arguments; clearTimeout(timeout); timeout = setTimeout(later, wait); };
  }

}
