import { Injectable } from '@angular/core';
import { IResourceMethodPromise, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod } from '@ngx-resource/core';
import { IMongooseResult, IQueryParams } from '../api.module';

export interface ICompileCategory {
  nmbr: number;
  name: string;
  description: string;
  shortdescription: string;
  categoryimage: string;
  products: number;
  productList: IProduct[];
  selectedItems?: number;
  totalPrice?: number;
  childeren: ICompileCategory[];
}

export interface IProduct {
  productkey: string;
  productname?: string;
  productprice?: number;
  listprice?: number;
  rentalSlots?: RentalSlot[];
  deliveryDates?: number[]
  productimage?: string;
  productimages?: string[];
  brand?: string;
  productspec?: string;
  producttxt1?: string;
  metatitle?: string;
  metadescription?: string;
  deliveryDays?: number;
  maxHourOfDay?: string;
  disabled?: boolean;
  disableOnArrival?: boolean;
  disableOnDeparture?: boolean;
  skipOrderProcess?: boolean;
  rentalType?: string;
  unit?: string;
  options?: Option[];
  properties: Property[];
  quantityLabel?: string;
  courceschedulecount?: number;
  compileCategories?: ICompileCategory[];
  courceSlots?: {
    courcenmbr: number;
    companynmbr: number;
    sequence: number;
    scheduledate: number;
    starttime: number;
    endtime: number;
    employee: string;
    location: string;
    courcetxt1: string;
    courcetxt2: string;
    courcetxt3: string;
    courcetxt4: string;
    participants: number;
    maxparticipants: number;
  }[];
  quantity?: number;
}

export interface RentalSlot {
  nmbr?: number;
  name?: string;
  action?: string;
  startDateTime?: null;
  endDateTime?: number;
}

export interface Property {
  icon: string;
  value: string;
}

export interface Option {
  index?: number;
  optName: string;
  valInput?: string;
  values: Value[];
}

export interface Value {
  productkey?: string;
  value?: any;
  valName?: string;
}

@ResourceParams({
  pathPrefix: '/api/guest/product'
})
@Injectable({
  providedIn: 'root'
})
export class ProductResourceService extends Resource {

  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: ''
  })
  query: IResourceMethodPromise<IQueryParams, IMongooseResult<IProduct>>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/ondate/{!selecteddate}'
  })
  queryDate: IResourceMethodPromise<IQueryParams, IMongooseResult<IProduct>>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/{!productkey}'
  })
  getOne: IResourceMethodPromise<IQueryParams, IProduct>;

}
