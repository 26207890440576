import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ReservationStoreService } from 'src/app/api/reservation/reservation.store';
import { MyBusinessStoreService } from '../../mybusiness.store';

export interface IComponentConfig<I> {
  type: 'PRODUCTS_CAROUSEL' | 'PERSONAL_PLANNING' | 'WORKFLOW_SUGGESTIONS' | 'FAQ' | 'CHATS' | 'ANNOUNCEMENTS' | 'IMAGES' | 'PRODUCT_CATEGORIES' | 'PRODUCT_SEARCH' | 'CONTROLS';
  settings: I;
  header?: IHeader;
}

export interface IHeader {
  title?: string;
  inline?: boolean;
  useImageAsHeaderColor?: boolean;
  defaultBackHref?: string;
  type: 'SEARCHBAR' | 'TITLE',
  buttons?: 'CART'[]
  toolbars?: {
    type: 'PRODUCT_SEARCH' | 'DEMO_CONTROLS_BANNER',
    color: string,
    minHeight?: string,
    settings?: any
  }[]
}

@Component({
  selector: 'app-components',
  templateUrl: './components.component.html',
  styleUrls: ['./components.component.scss']
})
export class ComponentsComponent implements OnInit {

  @Input() components;
  @Input() refreshSubject: Subject<any>;
  @Input() context;

  constructor(
    private mybStore: MyBusinessStoreService,
    private resStore: ReservationStoreService,
  ) { }

  get mybusiness() {
    return this.mybStore;
  }

  get reservation() {
    return this.resStore;
  }

  ngOnInit() { }


  trackComp(index, item) {
    return index;
  }

}
