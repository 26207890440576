import { Injectable } from '@angular/core';
import { IWorkflow, IWorkflowStep, WorkflowResourceService } from './workflow.resource';
import { IMongooseResult, IQueryParams } from '../api.module';
import { UtilService } from 'src/app/core/util.service';
import { ServiceBase } from 'src/app/core/service.base';
import { MessageService } from 'src/app/core/message.service';


@Injectable({
  providedIn: 'root'
})
export class WorkflowService extends ServiceBase {

  constructor(
    private resource: WorkflowResourceService,
    private utilService: UtilService,
  ) {
    super('WorkflowService');
  }


  getOne(nmbr: string) {
    if (!nmbr) {
      this.utilService.broadcastError(this.getCtx('getOne'), 'error_missing_nmbr', true);
    }
    return this.utilService.handleServerCall(this.getCtx('getOne'), async () => await this.resource.getOne({ nmbr }));
  }

  query(query: IQueryParams): Promise<IMongooseResult<IWorkflow>> {
    return this.utilService.handleServerCall(this.getCtx('query'), async () => await this.resource.query(query));
  }

  queryCategory(query?: IQueryParams) {
    return this.utilService.handleServerCall(this.getCtx('queryCategory'), async () => await this.resource.queryCategory(query));
  }

  querySubCategory(category: string, query?: IQueryParams) {
    return this.utilService.handleServerCall(this.getCtx('querySubCategory'), async () => await this.resource.querySubCategory({ category, ...query }));
  }

  queryStep(nmbr: string, query?: IQueryParams): Promise<IWorkflowStep[]> {
    if (!nmbr) {
      this.utilService.broadcastError(this.getCtx('queryStep'), 'error_missing_nmbr', true);
    }
    return this.utilService.handleServerCall(this.getCtx('queryStep'), async () => await this.resource.queryStep({ nmbr, ...query }));
  }
}
