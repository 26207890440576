import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AccommodationService, IDomoticaGroup } from 'src/app/api/accommodation/accommodation.service';
import { IComponentConfig } from '../components/components.component';
import { ChatService } from 'src/app/api/chat/chat.service';
import { SessionStoreService } from 'src/app/api/session/session.store';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss'],
})
export class ControlsComponent implements OnInit {
  @Input() config: IComponentConfig<{
    noResultsMessage?: string;
  }>;
  @Input() refreshSubject: Subject<any>;
  @Input() context;

  groups: (IDomoticaGroup & {
    controls: any[];
    childeren: IDomoticaGroup[],
  })[];

  controls;

  constructor(
    private accomodationService: AccommodationService,
    private changeRef: ChangeDetectorRef,
    private sessionStore: SessionStoreService
  ) {
  }

  get settings() {
    return this.config.settings;
  }

  get emergencyPhone() {
    return this.sessionStore.emergencyPhone;
  }


  async ngOnInit() {
    if (this.refreshSubject) {
      this.refreshSubject.subscribe(() => {
        this.refreshData();
      });
    }
  }

  async refreshData() {
    this.groups = (await this.accomodationService.getControls()).filter(c => c.type === 'DOMOTICA CATEGORY');
    this.changeRef.detectChanges();
  }

  callEmergencyPhone() {
    window.open(this.emergencyPhone, '_blank');
  }

}