import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { SessionStoreService } from '../api/session/session.store';
import { AppTranslateService } from './translate.service';
import { UtilService } from './util.service';

@Pipe({
  name: 'sessionStrInt'
})
export class SessionStrIntPipe implements PipeTransform {

  constructor(
    private sessionStore: SessionStoreService,
    private utilService: UtilService,
    private translateService: AppTranslateService,
  ) {

  }

  transform(input: string, data?: any): string {
    const user = this.sessionStore.userData;
    return this.utilService.interpolateStr({ ...data, user, greeting: this.greeting }, input);
  }

  get greeting() {
    const m = moment()
    const h = m.hour();
    switch (true) {
      case h > 18:
        return this.translateService.instant('greet_evening');
      case h > 12:
        return this.translateService.instant('greet_midday');
      case h > 3:
        return this.translateService.instant('greet_morning');
      default:
        return this.translateService.instant('greet_day');
    }
    return ''
  }



}


@Pipe({
  name: 'strInt'
})
export class strIntPipe implements PipeTransform {

  constructor(
    private utilService: UtilService,
  ) { }

  transform(input: string, data?: any): string {
    const response = this.utilService.interpolateStr({ ...data }, input);
    return response;
  }

}


@Pipe({
  name: 'strEval'
})
export class strEvalPipe implements PipeTransform {

  constructor(
    private utilService: UtilService,
  ) { }

  transform(input: string, data?: any): any {
    try {
      const response = this.utilService.evalStr(data, input);
      return response;
    } catch (error) {
      console.error(error);
    }
  }
}



