import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { SessionService } from "../api/session/session.service";
import { SessionStoreService } from "../api/session/session.store";


@Injectable()
export class StaticPageConfigResolver implements Resolve<any> {
  constructor(
    private sessionStore: SessionStoreService,
    private sessionService: SessionService,
  ) { }

  async resolve(route: ActivatedRouteSnapshot) {
    // if (!this.sessionStore.isAuthenticated
    //   || route.data['pageName'] === 'login'
    //   || route.data['pageName'] === 'resetPassword'
    //   || route.data['pageName'] === 'activate') {
    // }
    await this.sessionService.refreshUserSettings();
    const configs = this.sessionStore?.settings?.staticPages;
    try {
      const config = configs[route.data['pageName']];
      return config;
    } catch (error) {
      return false;
    }
  }
}

@Injectable()
export class PermissionsResolver implements Resolve<any> {
  constructor(
    private sessionStore: SessionStoreService,
    private sessionService: SessionService,
  ) { }

  async resolve(route: ActivatedRouteSnapshot) {
    if (!this.sessionStore.isAuthenticated
      || route.data['pageName'] === 'login'
      || route.data['pageName'] === 'resetPassword'
      || route.data['pageName'] === 'activate') {
      await this.sessionService.refreshUserSettings();
    }
    return this.sessionStore?.settings.permissions;
  }
}