import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { ProductService } from 'src/app/api/product/product.service';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductItemComponent implements OnInit {
  @Input() item: any;
  @Input() button: any;
  @Input() isSuggestion: any;
  @Input() hidePrice: any;
  @Input() size: string;
  @Input() objectkey: string;
  @Input() lines;
  @Input() image = 'square'
  @Input() noLink
  @Input() linkQueryParams;

  @Input() cardDetailsTemplate: any;
  notFound: boolean;

  link: any[];

  queryParams;

  constructor(
    private service: ProductService,
    private changeRef: ChangeDetectorRef,
    private activeRoute: ActivatedRoute,
  ) { }

  async ngOnInit() {
    this.queryParams = this.getQueryParams();
    try {
      if (!this.item) {
        if (this.objectkey) {
          this.item = await this.service.getOne(this.objectkey);
        } else {
          this.notFound = true;
        }
      }
      this.link = !this.noLink ? ['/base/products/detail', this.item.productkey] : null;
    } catch (error) {
      this.notFound = true;
    } finally {
      this.changeRef.detectChanges();
    }
  }

  private getQueryParams(): any {
    let queryParams = {};
    let route = this.activeRoute.snapshot;
    while (route.parent) {
      queryParams = { ...queryParams, ...route.queryParams };
      route = route.parent;
    }
    return queryParams;
  }



  get courceSlot() {
    try {
      return this.item.courceSlots[0];
    } catch (error) {
    }
  }
  get rentalSlot() {
    try {
      return this.item.rentalSlots[0];
    } catch (error) {
    }
  }

  get bookTimeFormatted() {
    const result = [];
    if (this.courceSlot) {
      if (this.courceSlot?.starttime) {
        result.push(moment(this.courceSlot?.starttime).format('HH:mm'));
      }
      if (this.courceSlot?.starttime && this.courceSlot?.endtime) {
        result.push('-');
      }
      if (this.courceSlot?.endtime) {
        result.push(moment(this.courceSlot?.endtime).format('HH:mm'));
      }
    } else if (this.rentalSlot) {
      if (this.rentalSlot?.startDateTime) {
        result.push('From ' + moment(this.rentalSlot?.startDateTime).format('HH:mm'));
      }
      // if (this.rentalSlot?.startDateTime && this.rentalSlot?.endDateTime) {
      //   result.push('-');
      // }
      // if (this.rentalSlot?.endDateTime) {
      //   result.push(moment(this.rentalSlot?.endDateTime).format('HH:mm'));
      // }
    }
    return result.join(' ');

  }

  get showSpots() {
    const c = this.courceSlot;
    if (c) {
      if (c.maxparticipants > 1) {
        if ((c.participants / c.maxparticipants) > .5) {
          return true;
        }
      }
    }
    return false;
  }

  get showBookDate() {
    let date = Date.now();
    if (this.courceSlot) {
      return !moment(this.courceSlot.scheduledate).isSame(date, 'date');
    } else if (this.rentalSlot) {
      return !moment(this.rentalSlot.startDateTime).isSame(date, 'date');
    }
    return false;
  }

  get bookDateLabel() {
    if (this.showBookDate) {
      const m = moment(this.courceSlot?.scheduledate || this.rentalSlot?.startDateTime);
      if (m.isBetween(moment().subtract(1, 'day').startOf('day'), moment().add(1, 'day').endOf('day'))) {
        return m.calendar().slice(0, -11).trim()
      }
      return m.format('ddd D MMMM').trim();
    }
    return '';
  }

  get availableSpots() {
    const c = this.courceSlot;
    if (c && c.maxparticipants > 1) {
      return c.maxparticipants - c.participants;
    }
    return -1;
  }
}
