import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { LoaderService } from 'src/app/core/loader-service.service';
import { ServiceBase } from 'src/app/core/service.base';
import { AppTranslateService } from 'src/app/core/translate.service';
import { UtilService } from 'src/app/core/util.service';
import { IMongooseResult } from '../api.module';
import { ReservationStoreService } from '../reservation/reservation.store';
import { ChatResourceService, IUploadFile } from './chat.resource';

@Injectable({
  providedIn: 'root'
})
export class ChatService extends ServiceBase {

  constructor(
    private resource: ChatResourceService,
    private utilService: UtilService,
    private reservation: ReservationStoreService,
    private alertCtrl: AlertController,
    private translateService: AppTranslateService,
    private loaderService: LoaderService,
    private router: Router,
  ) {
    super('ChatService');
  }

  async query(query?: any): Promise<IMongooseResult<any>> {
    return this.utilService.handleServerCall(this.getCtx('query'), async () => await this.resource.query(query));
  }

  async queryUnread(): Promise<IMongooseResult<any>> {
    return this.utilService.handleServerCall(this.getCtx('queryUnread'), async () => await this.resource.query({
      unreadOnly: true
    }));
  }

  async getMain(): Promise<any> {
    return this.utilService.handleServerCall(this.getCtx('getMain'), async () => await this.resource.getOne({ nmbr: 'main' }))
  }

  async getOne(nmbr: number): Promise<any> {
    if (!nmbr) {
      this.utilService.broadcastError(this.getCtx('getOne'), 'missing_chat_nmbr', true);
    }
    return this.utilService.handleServerCall(this.getCtx('getOne'), async () => await this.resource.getOne({ nmbr }))
  }

  async markAsRead(nmbr: number): Promise<any> {
    if (!nmbr) {
      this.utilService.broadcastError(this.getCtx('markAsRead'), 'missing_chat_nmbr', true);
    }
    return this.utilService.handleServerCall(this.getCtx('markAsRead'), async () => await this.resource.markAsRead({ nmbr }), true)
  }

  async uploadFile(file: IUploadFile): Promise<any> {
    if (!file.nmbr) {
      this.utilService.broadcastError(this.getCtx('uploadFile'), 'missing_chat_nmbr', true);
    }
    if (!file.data) {
      this.utilService.broadcastError(this.getCtx('uploadFile'), 'missing_data', true);
    }
    if (!file.filename) {
      this.utilService.broadcastError(this.getCtx('uploadFile'), 'missing_filename', true);
    }

    return this.utilService.handleServerCall(this.getCtx('uploadFile'), async () => await this.resource.uploadFile(file), true)
  }

  async askForHelp(obj: { object1?: string, objectkey?: any, message?: string }, prepFunc?: any): Promise<any> {


    if (!this.reservation.chatNmbr) {
      this.utilService.broadcastError(this.getCtx('askForHelp'), 'missing_mainchat', true);
    }

    if (!!obj.object1 && !obj.objectkey) {
      this.utilService.broadcastError(this.getCtx('askForHelp'), 'missing_objectkey', true);
    }
    if (!!obj.object1 && !obj.objectkey && !obj.message) {
      this.utilService.broadcastError(this.getCtx('askForHelp'), 'missing_any_data', true);
    }

    const { object1, objectkey, message } = obj;

    (await this.alertCtrl.create({
      header: this.translateService.instant('ask_for_help_header'),
      message: this.translateService.instant('ask_for_help_message'),
      buttons: [this.translateService.instant('cancel'), {
        text: this.translateService.instant('ask_for_help_btn'),
        handler: async () => {
          if (prepFunc) {
            await prepFunc();
          }
          await this.loaderService.start();
          try {
            if ((object1 && objectkey) || message) {
              await this.utilService.handleServerCall(this.getCtx('askForHelp'), async () => await this.resource.askForHelp({ object1, objectkey, message }), true)
            }
            this.router.navigate(['/base/chats/detail', this.reservation.chatNmbr]);
          } catch (error) { } finally {
            await this.loaderService.stop();
          }
        }
      }]

    })).present();
  }

}
