import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { IDelivery, IOrder } from 'src/app/api/order/order.resource';
import { OrderService } from 'src/app/api/order/order.service';

@Component({
  selector: 'app-delivery-item',
  templateUrl: './delivery-item.component.html',
  styleUrls: ['./delivery-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryItemComponent implements OnInit, OnDestroy {
  @Input() item: IDelivery;
  @Input() order: IOrder;
  @Input() button: any;
  @Input() size: string;
  @Input() objectkey: string;
  @Input() lines;
  @Input() link;
  @Input() previewMode;
  @Input() isPlanning;

  @Output() removeItem = new EventEmitter();
  @Output() refresh = new EventEmitter();

  @Input() cardDetailsTemplate: any;
  notFound: boolean;
  showOptions = false;

  rentalWindowInterval;
  rentalExpireSeconds = 0;

  constructor(
    private changeRef: ChangeDetectorRef,
    private service: OrderService,
  ) { }

  async ngOnInit() {
    try {
      if (!this.item) {
        this.notFound = true;
      }
    } catch (error) {
      this.notFound = true;
    } finally {
      this.changeRef.detectChanges();
    }

    if (this.product.rentalWindowEndDateTime) {
      this.setRentalExpireSeconds();
      this.rentalWindowInterval = window.setInterval(() => {
        this.setRentalExpireSeconds();
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    window.clearInterval(this.rentalWindowInterval);
  }

  get isPending() {
    return this.item?.orderstate < 50;
  }

  get canEdit() {
    return this.item?.orderstate <= 10;
  }

  get isPast() {
    if (moment().isAfter(this.item.deliverydate, 'date')) {
      return true;
    }
    if (this.isPlanning) {
      if (this?.cource?.endtime) {
        return moment().isAfter(this.cource.endtime);
      }
      if (this.appointment?.appointmentend) {
        return moment().isAfter(this.appointment?.appointmentend);

      }
    }
    return false;
  }

  get isActive() {
    if (this.isPlanning) {
      if (this?.cource?.endtime) {
        return moment().isBetween(this.cource.starttime, this.cource.endtime);
      }
      if (this.appointment?.appointmentend) {
        return moment().isBetween(this.appointment.appointmentstart, this.appointment.appointmentend);

      }
    }
    return false;
  }

  get product() {
    return this.item?.mainProduct;
  }

  get cource() {
    return this.product.courceschedule;
  }

  get appointment() {
    return this.item?.appointment;
  }

  get hasButton() {
    return this.product.buttonLabel && this.product.buttonLink;
  }

  get isAccessKey() {
    return this.product.domoticaType === 'CENTER KEY'
  }

  get isMoveloBikes() {
    return this.product.domoticaType === 'MOVELO'
  }

  get detailBtnType() {
    if (this.hasButton) {
      return 'EXT_LINK';
    } else if (this.isAccessKey) {
      return 'ACCESSKEY'
    } else if (this.isMoveloBikes) {
      return 'EBIKES'
    }
    return '';
  }

  get appointmentTimeFormatted() {
    const result = [];
    if (this.appointment?.appointmentstart) {
      result.push(moment(this.appointment?.appointmentstart).format('HH:mm'));
    }
    if (this.appointment?.appointmentstart && this.appointment?.appointmentend) {
      result.push('-');
    }
    if (this.appointment?.appointmentend) {
      result.push(moment(this.appointment?.appointmentend).format('HH:mm'));
    }
    return result.join(' ');

  }

  get courceTimeFormatted() {
    const result = [];
    if (this.cource?.starttime) {
      result.push(moment(this.cource?.starttime).format('HH:mm'));
    }
    if (this.cource?.starttime && this.cource?.endtime) {
      result.push('-');
    }
    if (this.cource?.endtime) {
      result.push(moment(this.cource?.endtime).format('HH:mm'));
    }
    return result.join(' ');
  }

  get rentalExpired() {
    return moment().isAfter(this.product.rentalWindowEndDateTime);
  }

  get showImage() {
    return !!this.product?.productimage;
  }

  get isDetailPage() {
    return window.location.pathname.indexOf('/products/detail/') !== -1;
  }

  async refreshRentalWindow() {
    await this.service.refreshRentalWindow(this.item);
    this.refresh.emit();
  }

  setRentalExpireSeconds() {
    let sec = moment(this.product.rentalWindowEndDateTime).unix() - moment().unix();
    this.rentalExpireSeconds = sec > 0 ? sec : 0;
    this.changeRef.detectChanges();
  }

}
