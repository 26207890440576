import { Injectable } from '@angular/core';
import { ServiceBase } from 'src/app/core/service.base';
import { UtilService } from 'src/app/core/util.service';
import { IQueryParams } from './../api.module';
import { AccommodationResourceService } from './accommodation.resource';


export interface IDomoticaGroup {
  _id: string,
  name?: string,
  img?: string;
  childeren?: IDomoticaGroup[]
}

export interface IDomotica {
  id: string;
  action: string;
  state?: any;
}

@Injectable({
  providedIn: 'root'
})
export class AccommodationService extends ServiceBase {

  domotica = [];
  constructor(
    private resource: AccommodationResourceService,
    private utilService: UtilService,
  ) {
    super('AccommodationService');
  }

  getOne(id: string) {
    if (!id) {
      this.utilService.broadcastError(this.getCtx('getOne'), 'missing_accomodation_id', true)
    }
    return this.resource.getOne({ id });
  }

  async getCurrent() {
    return await this.resource.getOne({ id: 'current' });
  }


  async getControls() {
    return await this.resource.getControls();
  }

  query(query: IQueryParams) {
    return this.resource.query(query)
  }

  setDomotica(dom: any) {
    if (!dom) {
      this.utilService.broadcastError(this.getCtx('setDomotica'), 'missing_domotica_obj', true)
    }
    if (!dom.id) {
      this.utilService.broadcastError(this.getCtx('setDomotica'), 'missing_domotica_id', true)
    }
    if (!dom.action) {
      this.utilService.broadcastError(this.getCtx('setDomotica'), 'missing_domotica_action', true)
    }
    return this.resource.setDomotica(dom);
  }

  refreshDomotica(id: string) {
    if (!id) {
      this.utilService.broadcastError(this.getCtx('setDomotica'), 'missing_domotica_id', true)
    }
    return this.resource.refreshDomotica({ id });
  }
}
