import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsCountdown'
})
export class SecondsCountdownPipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      let h = Math.floor(value / 3600);
      let m = Math.floor((value - (h * 3600)) / 60);
      let s = value - (h * 3600) - (m * 60);

      let res = '';
      if (h) res += h.toString() + ':';

      res += ('0' + m.toString()).slice(-2) + ':'
      res += ('0' + s.toString()).slice(-2);

      return res;
    }
  }
}
