import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-control-category',
  templateUrl: './control-category.component.html',
  styleUrls: ['./control-category.component.scss'],
})
export class ControlCategoryComponent implements OnInit {

  @Input() item;
  @Input() isSub;

  routeParams: any;
  groupSlug: string;

  constructor(private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.routeParams = this.getRouteParams();
    const slug = this.routeParams?.categorySlug || '';
    this.groupSlug = [...slug.split('|'), this.item.name].filter(a => a).join('|');

  }

  private getRouteParams(): any {
    let params = {};
    let route = this.activeRoute.snapshot;
    while (route.parent) {
      params = { ...params, ...route.params };
      route = route.parent;
    }
    return params;
  }

}
