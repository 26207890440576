import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { delay, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { AccountService } from '../api/account/account.service';
import { ReservationService } from '../api/reservation/reservation.service';
import { LoaderService } from './loader-service.service';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private loaderService: LoaderService,
    private messageService: MessageService,
  ) { }

  async handleServerCall(context: string, func: any, skipLoader?: boolean) {
    try {
      if (!skipLoader) {
        this.loaderService.start();
      }
      const resp = await func();
      this.messageService.broadcast(context.toLowerCase(), resp);
      return resp;
    } catch (error) {
      this.messageService.broadcast(context.toLowerCase(), undefined);
      throw error;
    } finally {
      if (!skipLoader) {
        this.loaderService.stop();
      }
    }
  }

  broadcastInfo(context: string, msg: string) {
    const message = [context.toLowerCase().replace(/\./gi, '_'), msg].filter(a => a).join('_').toLowerCase();
    this.messageService.broadcast('system.info', message);
  }

  broadcastError(context: string, error: string, throwError?: boolean) {
    const message = [context.toLowerCase().replace(/\./gi, '_'), error].filter(a => a).join('_').toLowerCase();
    this.messageService.broadcast('system.error', message);
    if (throwError) {
      throw new Error(message);
    }
  }

  evalStr(context: any, str: string) {
    if (str) {
      if (str.indexOf(';') > -1) {
        return str.split(';').forEach(s => new Function(...Object.keys(context), 'return ' + s)(...Object.values(context)));
      } else {
        return new Function(...Object.keys(context), 'return ' + str)(...Object.values(context));
      }
    }
  }

  interpolateStr(context: any, str: string) {
    try {
      var func = new Function(...Object.keys(context), "return `" + str + "`;")
      return func(...Object.values(context));
    } catch (error) {
      console.error(error);
    }
  }




  checkPassword(controlName: string, matchingControlName: string) {
    return (formControl: FormGroup): ValidationErrors | null => {
      if (!formControl.parent) {
        return null;
      }
      const control = formControl.parent.controls[controlName];
      const matchingControl = formControl.parent.controls[matchingControlName];
      if (control?.value !== matchingControl?.value) {
        return { checkPassword: true };
      } else {
        return null;
      }
    }
  }



  passwordStrength(control: FormControl): ValidationErrors | null {
    if (!control.value) return null;


    let hasNumber = /\d/.test(control.value);
    let hasUpper = /[A-Z]/.test(control.value);
    let hasLower = /[a-z]/.test(control.value);
    let hasLength = (control.value || '').length > 5;
    const valid = hasNumber && hasUpper && hasLower && hasLength;
    if (!valid) {
      // return what´s not valid
      return { passwordStrength: true };
    }
    return null;
  }

  emailExistsInReservation(reservationService: ReservationService) {
    return (control: AbstractControl): Promise<ValidationErrors> => {
      return new Promise(resolve => {
        if (!control.value) return resolve(null);
        reservationService.checkMail(control.value).then(resp => {
          resolve(resp.message === 'FOUND' ? { emailExistsInReservation: true } : null)
        });
      })
    };
  }

  asyncEmailExists(accountService: AccountService, hasToExist?: boolean): AsyncValidatorFn {

    return (control: AbstractControl): Observable<ValidationErrors> =>
      control.value
        ? of(control.value)
          .pipe(
            delay(500),
            distinctUntilChanged(),
            switchMap(() => accountService.checkEmail(control.value)),
            map((data: any) => hasToExist ? (data.message === 'FOUND' ? null : { emailExists: true }) : (data.message === 'FOUND' ? { emailExists: true } : null))
          ) : of();
  }
}
