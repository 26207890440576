import { Component, Input, OnInit } from '@angular/core';
import { IComponentConfig } from '../components/components.component';
import { Subject } from 'rxjs';
import { SwiperOptions } from 'swiper';

export interface IImagesSettings {
  images: string[];

}

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss'],
})
export class ImagesComponent implements OnInit {
  @Input() config: IComponentConfig<IImagesSettings>;
  @Input() refreshSubject: Subject<any>;
  @Input() defaultHref;
  @Input() context;


  swiperOptions: SwiperOptions = {
    slidesPerView: 1,
    parallax: {
      enabled: true
    },
    freeMode: {
      enabled: true,
      sticky: true,
    },
    shortSwipes: false,

    speed: 500,
    pagination: {
      enabled: true,
      clickable: true,
      type: 'bullets'
    },
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
      pauseOnMouseEnter: false,
    }
  };

  constructor() { }

  ngOnInit() {
    if (this.refreshSubject) {
      this.refreshSubject.subscribe(() => {
        this.refreshData();
      });
    }
  }


  async refreshData() {

  }

  get settings(): IImagesSettings {
    return this.config?.settings
  }

  trackImage(index, img) {
    return img;
  }

}
