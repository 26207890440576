import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStoreService {
  private storedSettings = JSON.parse(localStorage.getItem('settings') || '{}');

  constructor() { }


  reset() {
    this.auth = undefined;
    localStorage.clear();
  }

  get auth() {
    try {
      return JSON.parse(localStorage.getItem('auth') || '{}')
    } catch (error) { }
    return {}
  }

  set auth(v) {
    localStorage.setItem('auth', JSON.stringify(v));
  }

  set settings(v) {
    this.storedSettings = v;
    localStorage.setItem('settings', JSON.stringify(v));
  }

  get settings() {
    return this.storedSettings;
  }

  get isAuthenticated(): boolean {
    const storedAuth = this.auth;
    return !!storedAuth && !!storedAuth?.accesstoken && (!!storedAuth?.employee || !!storedAuth?.guest)
  }

  get userData() {
    const storedAuth = this.auth;
    return storedAuth.guest || storedAuth.employee;
  }

  get erpUID() {
    const ud = this.userData;
    if (ud) {
      return ud.erpUID;
    }
    return '';
  }

  get userRole(): 'GUEST' | 'LOCAL' {
    const settings = this.storedSettings.settings;
    if (settings) {
      return settings.role;
    }
    return 'GUEST';
  }

  get cellPhone() {
    const ud = this.userData;
    if (ud) {
      return ud.cellphone;
    }
    return '';
  }

  get email() {
    const ud = this.userData;
    if (ud) {
      return ud.email;
    }
    return '';
  }

  get workgroup() {
    const ud = this.userData;
    if (ud) {
      return ud.erpWorkgroup || '';
    }
    return '';
  }

  public getPermission(value: string, obj?: any) {
    try {
      if (!this.storedSettings.permissions) return false;
      let [curr, ...remainParts] = value.split('.');
      const remain = remainParts.join('.');
      if (!curr) return false;
      if (!obj) obj = this.storedSettings.permissions;
      if (!remain) return obj[curr];
      return this.getPermission(remain, obj[curr]);
    } catch (error) {
      return false;
    }
  }




  public get moveloStationCoordinates(): { latitude: number, longitude: number } {
    return this.getPermission('movelo.stationCoordinates');
  }

  public get emergencyPhone(): string {
    return this.getPermission('emergencyPhone')?.href;
    // || 'tel:+31850806406'
  }


  get emailIsVerified(): boolean {
    const storedAuth = this.auth;
    return storedAuth.user.emailVerified === true;
  }

  get resetReservationHash(): string {
    const storedAuth = this.auth;
    return storedAuth.user.resetHash;
  }

  get activeReservationId() {
    const storedAuth = this.auth;
    return storedAuth.guest.activeReservationId;
  }

  get accessToken() {
    if (this.isAuthenticated) {
      const storedAuth = this.auth;
      return storedAuth.accesstoken;
    }
    return '';
  }

  get refreshToken() {
    if (this.isAuthenticated) {
      const storedAuth = this.auth;
      return storedAuth.refreshtoken;
    }
    return '';
  }

  public setEmailVerified(value: boolean) {
    if (this.isAuthenticated) {
      const storedAuth = this.auth;
      storedAuth.user.emailVerified = value;
      this.auth = storedAuth;
    }
  }

  public clear() {
    this.auth = undefined;
    localStorage.clear();
  }
}
