import { Component, Input, OnInit } from '@angular/core';
import { IComponentConfig } from '../components/components.component';
import { Subject } from 'rxjs';

interface IProductSearchSettings {
  noResultsMessage?: string;
  query?: any;
  overlayHeader?: string;
}


@Component({
  selector: 'app-product-searchbar',
  templateUrl: './product-searchbar.component.html',
  styleUrls: ['./product-searchbar.component.scss'],
})
export class ProductSearchbarComponent implements OnInit {
  @Input() config: IComponentConfig<IProductSearchSettings>;
  @Input() refreshSubject: Subject<any>;
  @Input() context;

  constructor() { }

  ngOnInit() { }

}
