<div *ngIf="paragraphs" style="max-height: calc(100vh - 150px); overflow-y: auto;">
  <div *ngIf="announcement.lettername" style="position: sticky; top:0;">
    <div class="ion-clamp-2 header" style="
    font-size: 22px; padding:var(--ion-padding); padding-bottom:0px;
    font-weight: 400 !important;
    text-align: center;
    " [innerHTML]="announcement.lettername"></div>
  </div>
  <swiper *ngIf="paragraphs && paragraphs.length > 0" #swiper [config]="swiperOptions" (slideChangeTransitionEnd)="updateIndex()">
    @for (p of paragraphs; track $index) {
    <ng-template swiperSlide>
      <div style="background-color: var(--ion-background-color); padding:var(--ion-padding); max-height: calc(100vh - 150px); ">
        <div class="header" [innerHTML]="p.paragraphchapter"></div>
        <div class="description" [innerHTML]="p.paragraph"></div>
      </div>
    </ng-template>
    }
  </swiper>
</div>
<ion-footer *ngIf="paragraphs">
  <div class="footer-wrapper">
    <ion-toolbar>
      <div *ngIf="NeedsAccept" class="description info-text">
        <p class="ion-no-margin">
          <span *ngIf="!isAccepted else alreadyAccepted">
            By accepting I confirm that i've read and understand this announcement
          </span>
          <ng-template #alreadyAccepted>
            You confirmed that you read and understand this announcement
          </ng-template>
        </p>
      </div>
      <div style="margin:var(--ion-padding);">
        <ion-button size="large" (click)=" nextSlide()" *ngIf="!canDismiss; else dismissBtn" expand="block" [color]="isLast ? 'primary' : 'secondary'">
          {{(!isLast ? 'Next' : NeedsAccept && !isAccepted ? 'Accept' : 'Dismiss')| appTranslate}}
          <ion-icon slot="end" [src]="isLast ? appBackend + '/assets/assets/fa/light/check.svg' : appBackend + '/assets/assets/fa/light/arrow-right.svg'"></ion-icon>
        </ion-button>
        <ng-template #dismissBtn>
          <ion-button size="large" (click)="dismiss()" *ngIf="canDismiss" expand="block" color="primary">
            {{'Dismiss'| appTranslate}}
            <ion-icon slot="end" [faIcon]="'check'"></ion-icon>
          </ion-button>
        </ng-template>
      </div>
    </ion-toolbar>
  </div>
</ion-footer>