import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { ChatService } from 'src/app/api/chat/chat.service';
import { IComponentConfig } from '../components/components.component';

interface IChatsSettings {
  hideHeader?: boolean,
  moreBtn?: {
    label?: string;
    icon?: string;
  }
}

export interface IChat {
  nmbr: number;
  companynmbr: number;
  creator: string;
  chatname: string;
  timestamp: number;
  chatstatus: string;
  object1: string;
  objectkey: string;
  chatpriority: string;
  actionholder: string;
  menulevel: number;
  unread: number;
  maxtimestamp: number;
  companyname: string;
  companycolor: string;
  lastparagraph: string;
  lastcreator: string;
  lasttimestamp: number;
  lastobject1: string;
  lastobjectkey: string;
  lastchatmembernmbr: number;
  chatstatustext: string;
  members: Member[];
  lastmessage: Lastmessage;
}

export interface Lastmessage {
  paragraph: string;
  creator: string;
  timestamp: number;
  object1: string;
  objectkey: string;
  chatmembernmbr: number;
  personalinfo: Personalinfo;
}

export interface Personalinfo {
  shortname?: string;
  firstname: string;
  surname: string;
  workgroup?: string;
  personimage: string;
  lastchatactivitytimestamp?: number;
  persontxt1: string;
  persontxt2: string;
  persontxt3: string;
  persontxt4: string;
  persontxt5: string;
  searchname?: string;
}

export interface Member {
  nmbr: number;
  chatnmbr: number;
  companynmbr: number;
  employee: string;
  chatmembermode: string;
  chatrelation: string;
  person: string;
  notificationmode: string;
  workgroup: string;
  personalinfo?: Personalinfo;
}

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss'],
})
export class ChatsComponent implements OnInit {
  @Input() config: IComponentConfig<IChatsSettings>;
  @Output() configChange = new EventEmitter<IChatsSettings>();
  @Input() refreshSubject: Subject<any>;
  @Input() context;


  mainChat: IChat;
  total = 0;
  visible = 0;

  otherChats: IChat[] = [];

  constructor(
    private service: ChatService,
    private changeRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.refreshSubject.subscribe(() => {
      this.refreshData();
    });
  }


  async refreshData() {
    this.mainChat = await this.service.getMain();
    const res = (await this.service.query()).results.filter(c => c.nmbr !== this.mainChat?.nmbr);
    this.total += res.length;
    this.configChange.emit({ ...this.config, hideHeader: this.total == 0 });

    this.otherChats = res.splice(0, 4);
    this.visible += this.otherChats.length;

    this.changeRef.detectChanges();
  }


  get settings(): IChatsSettings {
    return this.config.settings;
  }

}
