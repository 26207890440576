import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ChatService } from 'src/app/api/chat/chat.service';
import { SessionStoreService } from 'src/app/api/session/session.store';
import { MessageService } from '../../message.service';
import { AppTranslateService } from '../../translate.service';
import { UtilService } from '../../util.service';
import { IComponentConfig } from '../components/components.component';

interface IMainChatSettings {
  backgroundImage?: string;
  chatLabel?: string;
  moreBtn?: {
    label?: string;
    icon?: string;
  }
}

export interface IChat {
  nmbr: number;
  companynmbr: number;
  creator: string;
  chatname: string;
  timestamp: number;
  chatstatus: string;
  object1: string;
  objectkey: string;
  chatpriority: string;
  actionholder: string;
  memberNames?: string[];
  memberImages?: string[];
  menulevel: number;
  unread: number;
  maxtimestamp: number;
  companyname: string;
  companycolor: string;
  lastparagraph: string;
  lastcreator: string;
  lasttimestamp: number;
  lastobject1: string;
  lastobjectkey: string;
  lastchatmembernmbr: number;
  chatstatustext: string;
  members: Member[];
  lastmessage: Lastmessage;
}

export interface Lastmessage {
  paragraph: string;
  creator: string;
  timestamp: number;
  object1: string;
  objectkey: string;
  chatmembernmbr: number;
  personalinfo: Personalinfo;
}

export interface Personalinfo {
  shortname?: string;
  firstname: string;
  surname: string;
  workgroup?: string;
  personimage: string;
  lastchatactivitytimestamp?: number;
  persontxt1: string;
  persontxt2: string;
  persontxt3: string;
  persontxt4: string;
  persontxt5: string;
  searchname?: string;
}

export interface Member {
  nmbr: number;
  chatnmbr: number;
  companynmbr: number;
  employee: string;
  chatmembermode: string;
  chatrelation: string;
  person: string;
  notificationmode: string;
  workgroup: string;
  personalinfo?: Personalinfo;
}
const LEAVE_STATE = style({ opacity: 0, 'transform': 'translateY(120%)' });
const ENTER_STATE = style({ opacity: 1, 'transform': 'translateY(0)' });

@Component({
  selector: 'app-main-chat',
  templateUrl: './main-chat.component.html',
  styleUrls: ['./main-chat.component.scss'],
  animations: [
    trigger('animate', [
      transition(':enter', [
        LEAVE_STATE,
        animate('200ms ease-in-out', ENTER_STATE),
      ]),
      transition(':leave', [
        ENTER_STATE,
        animate('200ms ease-in-out', LEAVE_STATE),
      ]),
    ])

  ]
})
export class MainChatComponent implements OnInit, OnDestroy {
  @Input() config: IComponentConfig<IMainChatSettings>;
  @Input() refreshSubject: Subject<any>;
  @Input() context;


  mainChat: IChat;
  subs: Subscription[] = [];
  constructor(
    private service: ChatService,
    private changeRef: ChangeDetectorRef,
    private utilService: UtilService,
    private translateService: AppTranslateService,
    private sessionStore: SessionStoreService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    if (this.refreshSubject) {
      this.refreshSubject.subscribe(() => {
        this.refreshData();
      });
    }

    this.subs.push(this.messageService.subscribe('chatservice.queryunread', (data) => {
      if (data && this.mainChat) {
        this.mainChat = data.results.find(chat => chat.nmbr === this.mainChat.nmbr) || this.mainChat;
        this.changeRef.detectChanges();
      }
    }))

  }
  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }


  async refreshData() {
    this.mainChat = await this.service.getMain();
    this.changeRef.detectChanges();
  }

  get settings(): IMainChatSettings {
    return this.config.settings;
  }

  get paragraph() {
    return `<strong>${this.lastCreator}:</strong>&nbsp;` + this.mainChat?.lastparagraph.replace(/<[^>]*>/gmi, '');
  }

  get lastCreator() {
    if (this.mainChat.lastcreator === this.sessionStore.userData.erpUID) {
      return this.translateService.instant('you_label');
    } else {
      return this.mainChat?.lastmessage?.personalinfo?.firstname || this.mainChat?.lastcreator;
    }
  }

  get chatName() {
    if (this.settings?.chatLabel) {
      return this.utilService.interpolateStr({ chat: this.mainChat, isMainChat: true }, this.settings?.chatLabel)
    }
    return this.mainChat.chatname
  }

  get names() {
    try {
      const members = this.mainChat.memberNames;
      return members;
    } catch (error) { }
    return [];
  }

  get avatars(): string[] {
    try {
      return this.mainChat.memberImages
    } catch (error) { }
    return [];
  }


  get namesStr() {
    const names: string[] = JSON.parse(JSON.stringify(this.names));
    const last = names.pop();
    const res = names.join(', ');
    return (res ? res + ' & ' : '') + last;
  }




}
