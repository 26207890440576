import { Injectable } from '@angular/core';
import { IResourceMethodPromise, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod } from '@ngx-resource/core';


export interface IReservation {
  relation?: Relation;
  suiteAccess?: SuiteAccess;
  accommodations?: Accommodation[];
  moveloRentals?: MoveloRental[];
  id?: string;
  erpAPIConnector?: string;
  erpUID?: string;
  pmsUID?: string;
  location?: Location;
  invites?: any[];
  extraGuests?: any[];
  v?: number;
  controlsActivationDateTime?: Date;
  controlsDeactivationDateTime?: Date;
  daysTillGuideSelection?: number;
  endDateTime?: Date;
  erpOrderUID?: number;
  postalcode?: string;
  reservationDate?: Date;
  startDateTime?: Date;
  status?: number;
  syncFlag?: string;
  guideSelectionMailSendTimestamp?: Date;
  erpChatUID?: number;
  mainGuest?: MainGuest;
  guide?: Guide;
  guideSelectionTimestamp?: Date;
  guideSelectionReminderMailSendTimestamp?: Date;
  controlsAreActive?: boolean;
  firstTimeDoorOpened?: boolean;
  firstTimeDoorOpenedTimestamp?: Date;
  moveloReservations?: any[];
  announcementRead?: { [key: string]: string };
}

export interface Accommodation {
  domoticas?: string[];
  id?: string;
  name?: string;
  type?: string;
  number?: string;
  description?: string;
  imageSrc?: string;
  erpUID?: string;
  location?: string;
  v?: number;
  subtype?: string;
}

export interface Guide {
  intresets?: any[];
  id?: string;
  location?: string;
  erpUID?: string;
  firstname?: string;
  surname?: string;
  erpWorkgroup?: string;
  erpPersonUID?: string;
  questionnaire?: Questionnaire[];
  answerSuggestions?: AnswerSuggestion[];
  v?: number;
  welcomeMailSendTimestamp?: Date;
  disabled?: boolean;
  introduction?: string;
  appVersion?: number;
  selectionBoost?: number;
}

export interface AnswerSuggestion {
  id?: string;
  message?: string;
}

export interface Questionnaire {
  awnsers?: string[];
  id?: string;
  icon?: string;
  label?: string;
  intrest?: string;
  question?: string;
  awnser?: string;
  fixedValues?: boolean;
}

export interface Location {
  settings?: LocationSettings;
  address?: Address;
  intrawebLinks?: any[];
  homepageLinks?: any[];
  hiddenGems?: string[];
  faqLinks?: any[];
  commuicationERPAPIConnectors?: CommuicationERPAPIConnector[];
  employees?: any[];
  id?: string;
  name?: string;
  v?: number;
  description?: string;
  email?: string;
  emergencyPhone?: string;
  erpUserUID?: string;
  logoSrc?: string;
  phone?: string;
}

export interface Address {
  streetandnr?: string;
  postalcode?: string;
  city?: string;
  country?: string;
  longitude?: number;
  latitude?: number;
}

export interface CommuicationERPAPIConnector {
  info?: Info;
  settings?: CommuicationERPAPIConnectorSettings;
  id?: string;
  type?: string;
  name?: string;
  active?: boolean;
  url?: string;
  v?: number;
  status?: string;
  noRequests?: number;
  sequence?: number;
}

export interface Info {
  needUpdate?: boolean;
  buildNumber?: null;
}

export interface CommuicationERPAPIConnectorSettings {
  amountOfSessions?: number;
  accomodationAssetType1?: string;
}

export interface LocationSettings {
  shop?: boolean;
  daysTillGuideSelection?: number;
  chatStartTime?: string;
  chatEndTime?: string;
  lateCheckOutProductkey?: string;
  accomodationAssetType1?: string;
  chatMode?: string;
}

export interface MainGuest {
  id?: string;
  email?: string;
  firstname?: string;
  surname?: string;
  questionnaire?: any[];
  v?: number;
  activeReservationID?: string;
  erpPersonUID?: string;
  erpUID?: string;
  appVersion?: number;
}

export interface MoveloRental {
  rental_ID?: string;
  reservation?: Reservation;
  is_allowed_toFinish?: string;
  bike?: Bike;
  tapkey_access_token?: null;
  terms_and_conditions?: string;
}

export interface Bike {
  name?: string;
  bike_ID?: string;
  tracking_module_installed?: string;
  require_manual_mileage_submission?: string;
  require_manual_battery_level_submission?: string;
  mileage?: string;
  lock?: Lock;
}

export interface Lock {
  type?: string;
  manufacturer?: string;
  device_identifier?: string;
  api_identifier?: string;
  device_security_key?: string;
  eKey?: null;
  otps?: null;
  mac_address?: string;
  pin?: null;
  key?: null;
  has_chain_lock?: boolean;
}

export interface Reservation {
  reservation_ID?: string;
  from?: number;
  until?: number;
  expires?: Date;
  type?: string;
  departure_station?: Station;
  arrival_station?: Station;
  specifications?: Specifications;
  vehicle_type?: VehicleType;
  tariff?: Tariff;
  total_credits?: string;
}

export interface Station {
  station_ID?: string;
  name?: string;
  zip?: string;
  city?: string;
  image?: null;
  location_description?: string;
  lat?: string;
  lng?: string;
  proximity_detection_method?: string;
  proximity_detection_beacon_uUID?: null;
  proximity_detection_kontaktio_beacon_identifier?: null;
  tapkey_lock_ID?: null;
}

export interface Specifications {
  bike_series?: Bike_series;
  size?: string;
}

export interface Bike_series {
  bike_series_ID?: string;
  name?: string;
  image?: string;
}

export interface Tariff {
  tariff_ID?: string;
  name?: string;
  credits?: string;
  type?: string;
}

export interface VehicleType {
  vehicle_type_ID?: string;
  name?: string;
  pricing_multiplier?: string;
  image?: string;
}

export interface Relation {
  firstname?: string;
  surname?: string;
  country?: string;
  city?: string;
  streetandnr?: string;
  postalcode?: string;
  phone?: string;
  cellphone?: string;
  homephone?: string;
  email?: string;
  erpUID?: string;
}

export interface SuiteAccess {
  booking?: Booking;
  hasToken?: boolean;
}

export interface Booking {
  id?: number;
  uuid?: string;
  pmsUUID?: string;
  userID?: number;
  locationID?: number;
  roomID?: number;
  occupancy?: number;
  startAt?: Date;
  endAt?: Date;
  price?: number;
  notes?: null;
  status?: number;
  active?: boolean;
  cancelledAt?: null;
  completedAt?: null;
  cancelAllowed?: boolean;
  cancelBefore?: number;
  createdAt?: Date;
  updatedAt?: Date;
}


@ResourceParams({
  pathPrefix: '/api/guest/reservation'
})
@Injectable({
  providedIn: 'root'
})
export class ReservationResourceService extends Resource {

  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/current'
  })
  current: IResourceMethodPromise<any, IReservation>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/check/{!number}/{!method}/{!value}'
  })
  check: IResourceMethodPromise<{ number: string, method: string, value: string }, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/current/send-invite'
  })
  sendInvite: IResourceMethodPromise<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '/current/revoke-invite'
  })
  revokeInvite: IResourceMethodPromise<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/current/check-mail'
  })
  checkMail: IResourceMethodPromise<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/current/disable-demo'
  })
  deactivateDemo: IResourceMethodPromise<any, any>;



}
