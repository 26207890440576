import { Injectable, NgZone } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface Message {
  type: string;
  payload: any;
}

type MessageCallback = (payload: any) => void;

@Injectable({
  providedIn: 'root',
})
export class MessageService {

  constructor(public zone: NgZone) {
    if (!this.subject) {
      this.subject = new Subject<Message>();
    }
  }

  private set subject(input: Subject<Message>) {
    // Hack to make sure there is only 1 subject for the whole Application.
    (document as any).MYB_MESSAGE_SUBJECT = input;
  }

  private get subject(): Subject<Message> {
    // Hack to make sure there is only 1 subject for the whole Application.
    return (document as any).MYB_MESSAGE_SUBJECT;
  }

  broadcast(type: string, payload?: any) {
    if (type) {
      if (type.indexOf('.') !== -1 && !type.endsWith('*')) {
        const baseType = type.split('.')[0];
        this.subject.next({ type: baseType + '.*', payload: payload });
      }
      this.subject.next({ type: type, payload: payload });
    } else {
      console.trace('missing type broadcast');
    }
  }

  subscribe(type: string, callback: MessageCallback): Subscription {
    return this.subject
      .pipe(filter(message => message.type === type || type === '*'))
      .pipe(map(message => message.payload))
      .subscribe(callback);
  }
}
