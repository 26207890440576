import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import env from '../env.json';

@Injectable({
  providedIn: 'root',
})
export class AppTranslateService {

  constructor(public service: TranslateService) { }

  public instant(input) {
    const translation = this.service.instant(env.name + '_' + input);
    if (translation !== env.name + '_' + input) {
      return translation;
    }
    return this.service.instant(input)
  }



}
