
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, filter, mergeMap, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import build from '../env.json';
import { MessageService } from '../core/message.service';
import { SessionService } from './session/session.service';
import { SessionStoreService } from './session/session.store';
import { AlertController } from '@ionic/angular';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private sessionService: SessionService,
    private sessionStore: SessionStoreService,
    private messageService: MessageService,
    private alertCtrl: AlertController,
    private router: Router
  ) { }

  addToken(req: HttpRequest<any>, token?: string): HttpRequest<any> {
    let copyReq = req.clone({ headers: req.headers.set('app-version', build.version) });
    copyReq = copyReq.clone({ headers: copyReq.headers.set('app-build', build.build) });
    const at = this.sessionStore.accessToken;
    const rt = this.sessionStore.refreshToken;

    if (copyReq.url.indexOf('api') !== -1) {
      if (!copyReq.url.endsWith('auth/create')) {
        if (copyReq.url.endsWith('auth/refresh')) {
          copyReq = copyReq.clone({ headers: copyReq.headers.set('refreshtoken', token || rt) });
        } else {
          copyReq = copyReq.clone({ headers: copyReq.headers.set('accesstoken', token || at) });
        }
        return copyReq;
      }
    }
    return copyReq;
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authReq = this.addToken(req);
    return next.handle(authReq).pipe(
      tap((response) => {
        if (response instanceof HttpResponse) {
          switch (response.status) {
            case 211:
              this.messageService.broadcast('mybusiness.active', true);
              break;
          }
        }
      }),
      retryWhen(errors =>
        errors.pipe(
          mergeMap((error, retryCount) => {
            if (error.status === 0 || error.status === 503) {
              if (retryCount === 4) {
                this.alertCtrl.create({
                  header: error.status === 0 ? 'No Internet connection' : 'Can not connect to the server',
                  message: error.status === 0 ? 'It seems you have no internet connection. check your internet connection or try to disable wifi.<br /><br /><strong>Try again with a pull to refresh action.</strong>' : 'The server seems to be offline.<br /><br /><strong>Try again with a pull to refresh action.</strong>',
                  buttons: ['close']
                }).then(alert => alert.present());
                return throwError(error);
              } else {
                return of(error).pipe(delay(500));
              }
            } else {
              return throwError(error);
            }
          }),
          take(5)
        )
      ),
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 401:
              return this.handleRefresh(req, next, error);
            case 503:
            case 0:
              break;
            case 500:
            case 501:
            case 502:
            case 504:
              this.router.navigate(['/server-unavailable']);
              break;
            case 211:
              this.messageService.broadcast('mybusiness.active', true);
              break;
            case 200:
              break;
            case 531:
              this.messageService.broadcast('mybusiness.active', false);
              break;
            default:
              this.handleError(error);
              break;
          }
        }
        return throwError(error);
      })
    );
  }

  async handleError(e: any) {
    if (e.status !== 404) {
      const errorMessage: string = e.error && e.error.error ? e.error.error : 'unexpected_error';
      this.messageService.broadcast('system.error', errorMessage.toLowerCase())
    }
  }

  async handleRefresh(req: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse): Promise<any> {
    if (!this.sessionStore.isAuthenticated) {
      this.handleError(error);
      return throwError(error);
    }
    if (!this.isRefreshingToken) {
      try {
        this.isRefreshingToken = true;
        this.tokenSubject.next('');

        const auth = await this.sessionService.refreshToken();
        try {
          if (auth.accesstoken) {
            this.tokenSubject.next(auth.accesstoken);
            this.isRefreshingToken = false;
          } else {
            this.handleLogout();
            this.isRefreshingToken = false;
          }
          this.isRefreshingToken = false;
        } catch (e) {
          this.handleLogout();
        }
      } catch (error) {
        this.handleLogout();
        this.isRefreshingToken = false;
      }
    }

    return this.tokenSubject
      .pipe(filter(token => token != null))
      .pipe(take(1))
      .pipe(switchMap(token => {
        return next.handle(this.addToken(req, token));
      }));
  }

  handleLogout() {
    this.messageService.broadcast('system.error', 'session_expired');
    this.sessionService.logOut();
    if (window.location.pathname !== '/login') {
      this.router.navigate(['/login'], { replaceUrl: true });
    }
  }
}
