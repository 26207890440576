import { Injectable } from '@angular/core';
import { IResourceMethodPromise, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod } from '@ngx-resource/core';
import { IMongooseResult, IQueryParams } from './../api.module';


export interface IAccommodation {
  name: String,
  status: String,
  imageSrc: string,
  erpUID: String,
  type: string,
  number: string,
  domoticas: any[];
  // domoticas: {
  //   name: String,
  //   type: String,
  //   state: any,
  //   groups: String[],
  //   sequence: Number,
  //   apiType: String,
  //   apiUID: String,
  // }[]
}

@ResourceParams({
  pathPrefix: '/api/guest/accommodation'
})
@Injectable({
  providedIn: 'root'
})
export class AccommodationResourceService extends Resource {

  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/{!id}'
  })
  getOne: IResourceMethodPromise<{ id: string }, IAccommodation>;


  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/current/domotica'
  })
  getControls: IResourceMethodPromise<{ id: string }, any[]>;


  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: ''
  })
  query: IResourceMethodPromise<IQueryParams, IMongooseResult<IAccommodation>>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/current/domotica/{!id}/control'
  })
  setDomotica: IResourceMethodPromise<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/current/domotica/{!id}/refresh'
  })
  refreshDomotica: IResourceMethodPromise<any, any>;
}

