

export function debounce(callback: any, wait: any, immediate?: boolean, context = this) {
  let timeout: any = null;
  let callbackArgs: any = null;
  const later = () => callback.apply(context, callbackArgs);
  if (immediate) {
    later();
  }
  return function () { callbackArgs = arguments; clearTimeout(timeout); timeout = setTimeout(later, wait); };
}