import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentFormat'
})
export class MomentFormatPipe implements PipeTransform {

  transform(value: any, arg?: any): any {
    let m: any = undefined;
    if (('' + value).replace(/[0-9]/gmi, '') === '') {
      m = moment(parseInt(value, 10));
    } else if (value) {
      m = moment(value);
    }

    if (m) {
      return m.format(arg);
    } else {
      return '';
    }
  }


}



