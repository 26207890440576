import { Injectable } from '@angular/core';
import { ServiceBase } from 'src/app/core/service.base';
import { UtilService } from 'src/app/core/util.service';
import { SessionStoreService } from '../session/session.store';
import { ReservationResourceService } from './reservation.resource';
import { ReservationStoreService } from './reservation.store';
import { MessageService } from 'src/app/core/message.service';


@Injectable({
  providedIn: 'root'
})
export class ReservationService extends ServiceBase {

  constructor(
    private resource: ReservationResourceService,
    private store: ReservationStoreService,
    private sessiopnStore: SessionStoreService,
    private utilService: UtilService,
    private messageService: MessageService,
  ) {
    super('ReservationService');
  }

  async refreshCurrentStored() {
    let data;
    try {
      if (this.sessiopnStore.isAuthenticated) {
        data = await this.resource.current();
        this.store.current = data;
      } else {
        this.store.current = undefined;
      }
    } catch (error) {
      this.store.current = undefined;
    }
    return data;
  }

  check(number: any, method: string, value: string) {
    if (!number) {
      this.utilService.broadcastError(this.getCtx('check'), 'missing_reservation_check_nmbr', true);
    }
    if (!method) {
      this.utilService.broadcastError(this.getCtx('check'), 'error_missing_reservation_check_method', true);
    }
    if (!value) {
      this.utilService.broadcastError(this.getCtx('check'), 'error_missing_reservation_check_value', true);
    }
    return this.utilService.handleServerCall(this.getCtx('check'), async () => {
      try {
        return await this.resource.check({ number, method, value })
      } catch (error) {
        if (error.status === 404) {
          this.messageService.broadcast('system.error', error.body.error.toLowerCase());
        }
        throw new Error(error);
      }
    });
  }

  sendInvite(email: string) {
    if (!email) {
      this.utilService.broadcastError(this.getCtx('sendInvite'), 'error_missing_send_invite_email', true);
    }
    return this.utilService.handleServerCall(this.getCtx('sendInvite'), async () => this.resource.sendInvite({ email }));
  }

  revokeInvite(email: string) {
    if (!email) {
      this.utilService.broadcastError(this.getCtx('revokeInvite'), 'error_missing_revoke_invite_email', true);
    }
    return this.utilService.handleServerCall(this.getCtx('revokeInvite'), async () => this.resource.revokeInvite({ email }));
  }

  checkMail(email: string) {
    if (!email) {
      this.utilService.broadcastError(this.getCtx('checkMail'), 'error_missing_check_mail_email', true);
    }
    return this.utilService.handleServerCall(this.getCtx('checkMail'), async () => this.resource.checkMail({ email }));
  }

  deactivateDemo() {
    this.utilService.handleServerCall(this.getCtx('deactivateDemo'), async () => {
      this.store.current = await this.resource.deactivateDemo()
    });
  }

}
