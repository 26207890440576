<ng-container *ngIf="suggestions?.length > 0; else noResults">
  <swiper #swiperRef [config]="swiperOptions" [slidesOffsetAfter]="slidesOffsetAfter" (slideChangeTransitionStart)="onSlideChange('START',$event)" (slideChange)="onSlideChange('START',$event)" (slideChangeTransitionEnd)="onSlideChange('END',$event)">
    @for (s of suggestions; track index; let index = $index;) {
    <ng-template swiperSlide>
      <div class="suggestion" [ngClass]="{'active-start': index === activeStartIndex, 'active-end': index === activeEndIndex}">
        <img style="height:100%; object-fit: cover; width:100%;" *ngIf="s.workflowimage" [src]="s.workflowimage" />
        <div class="context">
          <div class="myb-flex horizontal">
            <div class="wrapper">
              <div class="content">
                <div data-swiper-parallax="-120" class="description ion-clamp-2">test</div>
                <div data-swiper-parallax="-60" class="header  ion-clamp-2">test</div>
              </div>
              <ion-icon data-swiper-parallax="-5" slot="end" [faIcon]="'chevron-right'"></ion-icon>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    }
  </swiper>
  <div style="margin:0 7px;">
    <ion-button *ngIf="settings.moreBtn" expand="block" color="primary">
      {{settings.moreBtn.label || 'view suggestions'}}
      <ion-icon slot="end" [faIcon]="'chevron-right'"></ion-icon>
    </ion-button>
  </div>
</ng-container>
<ng-template #noResults>
  <div class="no-results-message">
    {{(settings.noResultsMessage || 'No items')| appTranslate}}
  </div>

</ng-template>