import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SessionGuardService } from './api/session/session.guard';
import { PermissionsResolver } from './core/static-page-config.resolver';
import { ReservationGuardService } from './api/reservation/reservation.guard';

@Injectable({
  providedIn: 'root'
})
export class WaitForTranslationsGuardService implements CanActivate {

  constructor(private service: TranslateService) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const key = 'translation_test'
    const res = await this.service.get(key).toPromise();
    if (res !== key) {
      return true;
    } else {
      await this.service.reloadLang(this.service.currentLang).toPromise();
    }
    return true;
  }
}

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }, {
    path: 'login',
    canActivate: [WaitForTranslationsGuardService],
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  }, {
    path: 'base',
    loadChildren: () => import('./base/base.module').then(m => m.BasePageModule)
  },
  {
    path: 'activate',
    canActivate: [WaitForTranslationsGuardService],
    loadChildren: () => import('./activate/activate.module').then(m => m.ActivatePageModule)
  },
  {
    path: 'wait',
    canActivate: [SessionGuardService, ReservationGuardService],
    resolve: {
      permissions: PermissionsResolver,
    },
    loadChildren: () => import('./wait/wait.module').then(m => m.WaitPageModule)
  },
  {
    path: 'server-unavailable',
    loadChildren: () => import('./server-unavailable/server-unavailable.module').then(m => m.ServerUnavailablePageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  providers: [WaitForTranslationsGuardService],
  exports: [RouterModule]
})
export class AppRoutingModule { }
