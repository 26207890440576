import { Injectable } from '@angular/core';
import { ServiceBase } from 'src/app/core/service.base';
import { UtilService } from 'src/app/core/util.service';
import { IMongooseResult } from '../api.module';
import { AnnouncementResourceService, IAnnoucement } from './announcement.resource';


@Injectable({
  providedIn: 'root'
})
export class AnnouncementService extends ServiceBase {

  constructor(
    private resource: AnnouncementResourceService,
    private utilService: UtilService,
  ) {
    super('AnnouncementService');
  }


  async query(query?: any): Promise<IMongooseResult<IAnnoucement>> {
    return this.utilService.handleServerCall(this.getCtx('query'), async () => await this.resource.query(query));

  }

  async getNew(): Promise<IAnnoucement[]> {
    return this.utilService.handleServerCall(this.getCtx('getNew'), async () => await this.resource.getNew());
  }

  async getHook(hook: string): Promise<IAnnoucement[]> {
    return this.utilService.handleServerCall(this.getCtx('getHook'), async () => await this.resource.getHook({ hook }));
  }

  async getOne(nmbr: number): Promise<IAnnoucement> {
    if (!nmbr) {
      this.utilService.broadcastError(this.getCtx('getOne'), 'missing_annoucement_nmbr', true);
    }
    return this.utilService.handleServerCall(this.getCtx('getOne'), async () => await this.resource.getOne({ nmbr }))
  }

  async getContents(nmbr: number): Promise<any[]> {
    if (!nmbr) {
      this.utilService.broadcastError(this.getCtx('getContents'), 'missing_annoucement_nmbr', true);
    }
    return this.utilService.handleServerCall(this.getCtx('getContents'), async () => await this.resource.getContents({ nmbr }))
  }

  async markAsRead(nmbr: number): Promise<IAnnoucement> {
    if (!nmbr) {
      this.utilService.broadcastError(this.getCtx('markAsRead'), 'missing_annoucement_nmbr', true);
    }
    return this.utilService.handleServerCall(this.getCtx('markAsRead'), async () => await this.resource.markAsRead({ nmbr }))
  }

}
